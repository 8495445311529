import type { IInputChange } from 'types';

import { DragEvent, useCallback, useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import { GifLoader } from '@storybook';
import { useNotification } from 'hooks';
import {
	UploadedEnvelopeDocsState,
	useUploadMultiDoc,
} from 'views';
import { documentTypeRegex } from 'utils';
import { MESSAGE } from 'constant';

import { COMMON_CONST } from '../../store';
const { FILES_SUPPORTED, FILE_SIZE_LIMIT } = COMMON_CONST;

const { FILES_SUPPORTED_MESSAGE, FILE_LIMIT_EXCEEDS_MESSAGE } = MESSAGE;

const Loader = () => {
	return (
		<>
			<GifLoader className="loader-blue" dimension={60} />
			<div className="browse-file-sign-doc__loader-text">Uploading file...</div>
		</>
	);
};

const UploadLabel = () => {
	return (
		<>
			<i className="ri-file-upload-line browse-file__logo" />
			<div className="browse-file__label-text">
				<span className="browse-file__light-text">
					Drag and drop files or{' '}
					<span className="browse-file__click-label">Browse file</span>
				</span>
			</div>
			<div className="browse-file__description">{FILES_SUPPORTED}</div>
			<div className="browse-file__description">{FILE_SIZE_LIMIT}</div>
		</>
	);
};

export const BrowseFile = () => {
	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);

	const { errorNotification } = useNotification();
	const { uploadDoc } = useUploadMultiDoc();

	const { isLoading } = envelopeData;

	const handleImportPdf = useCallback(
		async (e: IInputChange | File, type?: 'drag' | 'browse') => {
			/**
			 * @Manish here we are assigning the file after extracting from the event based on the the type (drag or browse)
			 * */
			const file: File =
				(type === 'drag'
					? (e as File)
					: (e as IInputChange).target.files?.[0]) ?? ({} as File);
			if (file && file.size > 10026851) {
				errorNotification(FILE_LIMIT_EXCEEDS_MESSAGE);
			} else if (file && !documentTypeRegex.test(file.type)) {
				errorNotification(FILES_SUPPORTED_MESSAGE);
			} else if (file) {
				const formData = new FormData();
				formData.append('file', file);
				formData.append('originalname', file.name || 'Document');
				formData.append('size', file.size.toString());
				formData.append('extension', file.type);
				uploadDoc(formData);
			}
			if (type !== 'drag') {
				/**
				 *  here we are resetting the event so that user can upload the same file again
				 * */
				(e as IInputChange).target.value = '';
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[uploadDoc]
	);

	/**
	 *  here we are avoiding the drag over effect when ever we are uploading any document using the drag and upload method
	 * */
	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	/**
	 *  here we are writting the drag and upload function so that user can drop the file to upload the document
	 * */
	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files);
			handleImportPdf(files?.[0] as File, 'drag');
		},
		[handleImportPdf]
	);

	const renderLoadingScreen = useMemo((): JSX.Element => {
		return isLoading ? <Loader /> : <UploadLabel />;
	}, [isLoading]);

	return (
		<div
			className="browse-file-sign-doc"
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			<label
				htmlFor="browse-file"
				className="browse-file__label-box browse-file__label-box-height"
			>
				{renderLoadingScreen}
				<input
					multiple={false}
					accept=".pdf, .doc, .docx"
					id="browse-file"
					type="file"
					title=""
					onChange={handleImportPdf}
					className={`browse-file__input ${
						isLoading && 'browse-file__input-loading'
					}`}
					disabled={isLoading}
				/>
			</label>
		</div>
	);
};
