import { MutableRefObject, useEffect, useMemo, useRef } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { GifLoader } from '@storybook';
import { DocumentProcessingState } from 'states';
import { ConfigDocumentDrop, ConfigNodeDrawer, RecipientsState } from 'views';
import { ASSETS } from 'constant';
import { DocumentPagesSelector } from 'states/documents';

import {
	DropItemsState,
	IRefProps,
	configPagesRefsState,
	useSetConfigDoc,
	useUndoRedo,
} from '../../store';

const { DOC_MISSING_SVG } = ASSETS;

export const DocumentLoader = () => {
	return (
		<div className='config-document__loader-wrapper'>
			<GifLoader dimension={60} text="Document is being processed." />
		</div>
	);
};

export const DocumentFallback = () => {
	return (
		<div className="config-document__placeholder">
			<img src={DOC_MISSING_SVG} alt="" loading="lazy" />
			<span>No documents to show</span>
		</div>
	);
};

export const ConfigDocument = ({ refs, showZindex = true }: IRefProps) => {
	const setConfigPagesRef = useSetRecoilState(configPagesRefsState);
	const processingDoc = useRecoilValue(DocumentProcessingState);
	const { handleUpdateRecipientsAndTabs } = useSetConfigDoc();
	const dropItems = useRecoilValue(DropItemsState);
	const recipients = useRecoilValue(RecipientsState);
	const { mainPages: documents } = useRecoilValue(DocumentPagesSelector);

	const dropContainerDimension: MutableRefObject<{
		width: number;
		height: number;
	}> = useRef<{ width: number; height: number }>({ width: 0, height: 0 });

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const configPagesRefList = useRef<any>([]);


	useEffect(() => {
		if (recipients.length) {
			handleUpdateRecipientsAndTabs(dropItems, recipients);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recipients]);

	/**
	 * event handler to handle resize end
	 * */

	const renderImages = useMemo(() => {
		if (!processingDoc) {
			return <DocumentLoader />;
		}
		if (documents.length === 0) {
			return <DocumentFallback />;
		}

		return documents.map((document) => {
			const { _id, documentId, docName, height, width, pageIndex } =
				document ?? {};
			return (
				<ConfigDocumentDrop
					key={_id}
					_id={_id}
					height={height}
					width={width}
					idx={pageIndex}
					documentId={documentId}
					documentName={docName}
					dropContainerDimension={dropContainerDimension}
					showZindex={showZindex}
				/>
			);
		});
	}, [documents, processingDoc, showZindex]);

	useUndoRedo(refs);

	useEffect(() => {
		setConfigPagesRef({ current: [...configPagesRefList.current] });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [configPagesRefList]);

	return (
		<div className="config-document" id="config-doc-id">
			{renderImages}
			<ConfigNodeDrawer dropContainerDimension={dropContainerDimension} />
		</div>
	);
};
