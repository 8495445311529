import { FC, useEffect, useMemo, useRef } from 'react';

import { GifLoader } from '@storybook';
import { GetQueryParams } from 'utils';
import { ConfigDocumentLoadingStatus, ConfigurationDocumentsSelector, RecipientModal, SuccessScreen } from 'views';
import { useRecoilValue } from 'recoil';
import { EnvelopePurposeState, IsViewOnlyConfigState, isShowSuccessInvitation } from 'states';
import { useNetwork, useQueryParams } from 'hooks';
import { CONFIGURE_COMPLETE_MESSAGE, ENVELOPE_PURPOSE } from 'constant';

import {
  ConfigDocument,
  ConfigFooter,
  ConfigHeader,
  CustomDragLayer,
  IDropedItems,
  LeftSidebar,
  MenuTabState,
  PreviewDocument,
  useSetConfigDoc,
} from './components';

interface IEditorDashboard {
  envelopeId?: string;
}

export const EditorsDashboard:FC<IEditorDashboard> = ({envelopeId: envId}) => {
  const changes = useRef<Array<Array<IDropedItems>>>([]);
  const undoPointer = useRef<number>(0);
  const isUndoRedo = useRef<boolean>(false);
  const { fetchDocTemplate, fetchTabMenuItems, fetchEnvelope } =
    useSetConfigDoc();
  const { documents } = useRecoilValue(ConfigurationDocumentsSelector);
  const loadingStatus = useRecoilValue(ConfigDocumentLoadingStatus);
  const {getEnvelopeIdParams} = useQueryParams({updateState: true});
  const invitationSuccess = useRecoilValue(isShowSuccessInvitation);

  const viewOnly = useRecoilValue(IsViewOnlyConfigState)
	const isTemplateId = GetQueryParams('templateId');
  const envelopeId = getEnvelopeIdParams(envId);
  const { apiHostExists } = useNetwork();
  const purpose = useRecoilValue(EnvelopePurposeState);
  const menuTabs = useRecoilValue(MenuTabState);
  
	useEffect(() => {
    if (apiHostExists) {
      if (!documents?.[0]?.pages?.length) {
        if (envelopeId) {
          fetchEnvelope(envelopeId);
        }
      }
      if (isTemplateId) {
        fetchDocTemplate();
      }
      if (!Object.keys(menuTabs).length ) {
        fetchTabMenuItems();
      }
    }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [envelopeId, apiHostExists]);

  const renderEditorDashboard = useMemo(() => {
    if (loadingStatus === "loading") {
      return (
        <div className="Esign-dashboard__loader-wrapper">
          <GifLoader dimension={60} />
        </div>
      );
    }
    if (invitationSuccess) {
      return (
				<SuccessScreen
					message={
						purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT
							? CONFIGURE_COMPLETE_MESSAGE.PACKET_SAVED
							: CONFIGURE_COMPLETE_MESSAGE.INVIATATION_SENT
					}
				/>
			);
    }
    return (
      // paras : view doc scroll issue
      <div className={`editors-dashboard ${!viewOnly ? "editors-dashboard_config" : "editors-dashboard_readonly"}`}> 
          <div className="editors-dashboard__header">
          <ConfigHeader refs={{ changes, undoPointer, isUndoRedo, readOnly: viewOnly }} />
        </div>
        <CustomDragLayer />
        {!viewOnly && <div className="editors-dashboard__left_sidebar">
          <LeftSidebar />
        </div>}
        <div className={`editors-dashboard__${!viewOnly ? 'main' : 'main_readOnly'}`} id="editor-dashboard-main-id">
          <ConfigDocument refs={{ changes, undoPointer, isUndoRedo }} showZindex={true} />
        </div>

        <div className={`editors-dashboard__${!viewOnly ? "right_sidebar" : "right_sidebar_readOnly"}`}>
          <PreviewDocument />
        </div>
        {!viewOnly && <div className="editors-dashboard__footer">
           <ConfigFooter />
        </div>}
      </div>
    );
  }, [invitationSuccess, loadingStatus, purpose, viewOnly]);

  return (
    <>
      {renderEditorDashboard}
      <RecipientModal />
    </>
  );
};
