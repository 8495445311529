import { useCallback } from 'react';

import { useFirebaseInit } from 'hooks/firebase';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { UploadedDocumentFamily } from 'states/documents';
import {
	DocumentPreparingStatus,
	IEnvelopeListDetails,
	UPLOAD_DOCS_MESSAGE,
	UploadedEnvelopeDocsState,
} from 'views';
import { ref, onValue } from 'firebase/database';
import { useNotification } from 'hooks/use-notification';

type ISwapDoc = "true" | "false"

interface IReadTimeDocPage {
	deleted: boolean;
	docName: string;
	documentId: string;
	height: number;
	name: string;
	pageId: string;
	pageIndex: number;
	totalPages: number;
	width: number;
	swap?: ISwapDoc;
	oldDoc?: string;
}

export interface IFinalPage extends IReadTimeDocPage {
	_id: string;
}

export interface IRealtimeDocument {
	_id: string;
	name: string;
	pages: IFinalPage[];
}

const { SWAPPED_SUCCESS} = UPLOAD_DOCS_MESSAGE

export const useDocument = () => {
	const setUploadedDocs = useSetRecoilState(UploadedEnvelopeDocsState);
	const setDocumentBeingPrepared = useSetRecoilState(DocumentPreparingStatus);
	const { database } = useFirebaseInit();
	const { successNotification } = useNotification();

	const handleUpdateRealtimDocs = useRecoilCallback(
		({ set, snapshot }) =>
			(data: IReadTimeDocPage) => {
				const { contents: uploadedDocs } = snapshot.getLoadable(
					UploadedEnvelopeDocsState
				);
				/**
				 * here it checks if the new document is available in the swapped docs
				 * */
				const {pageId, pageIndex, totalPages, documentId} = data;
				const realData = { [pageId]: { ...data, _id: pageId } };
					const availableDocIndex = uploadedDocs?.data?.findIndex(
						({ id }: IEnvelopeListDetails) => id === documentId
					);
					if (availableDocIndex > -1) {
						set(UploadedDocumentFamily(data.pageId), realData);
						setUploadedDocs((prev) => {
							const prevState = structuredClone(prev.data);
							const currentDoc = prevState.find(
								({ id }: IEnvelopeListDetails) => id === documentId
							);
							if (currentDoc) {
								let arr = currentDoc.pages;
								if (arr.length === 0) {
									arr = new Array(totalPages);
								}
								arr[data.pageIndex] = pageId;
								currentDoc.pages = arr;
								currentDoc.pagesCount = `${totalPages}`;
							}
							if (data.swap === "true" && pageIndex === totalPages - 1) {
								setDocumentBeingPrepared(false);
								successNotification(SWAPPED_SUCCESS);
							}
							return { ...prev, data: prevState };
						});
					}
			},[UploadedEnvelopeDocsState]
	);

	const handleUpdateDocumentsFamily = useRecoilCallback(
		({ set, snapshot }) =>
			() => {
				const { contents: uploadedDocs } = snapshot.getLoadable(
					UploadedEnvelopeDocsState
				);
				const uploadedDocuments = uploadedDocs.data as IEnvelopeListDetails[];
				uploadedDocuments.forEach((doc: IEnvelopeListDetails) => {
					const { pages, totalPages = [] } = doc;
					pages.forEach((pageId: string) => {
						const foundPage = totalPages?.find((page) => page._id === pageId);
						if (foundPage) {
							const realData = {
								[pageId]: { ...foundPage, documentId: doc.id },
							};
							set(UploadedDocumentFamily(pageId), realData);
						}
					});
				});
			}
	);

	const handleRealtimeDocsCallback = useCallback(
		(paramId: string) => {
			// eslint-disable-next-line no-console
			console.info('Realtime firebase connected against', paramId);
			// Create a reference to the database path using the business variable
			const dataRef = ref(database, `/esign/${paramId}`);
			// Attach a listener to the dataRef to handle value changes
			onValue(dataRef, (snapshot) => {
				// Get the data from the snapshot

				const data = snapshot.val();
				if (data && data?.pageId) {
					handleUpdateRealtimDocs(data);
				}
			});
		},
		[database, handleUpdateRealtimDocs]
	);

	return {
		handleRealtimeDocsCallback,
		handleUpdateDocumentsFamily,
	};
};
