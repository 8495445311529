import { selector } from 'recoil';

import { PrepareDashboardState, UploadedEnvelopeDocsState } from './state';

// Selector to determine if the dashboard is in a state that can be edited or prepared
export const prepareDashboardSelector = selector({
  // Unique key for the selector
  key: 'is-prepare-dashboard-selector-key',

  // Get function to retrieve the state and compute the result
  get: ({ get }) => {
    // Get the current state from the PrepareDashboardState atom
    const state = get(PrepareDashboardState);

    // Extract the 'source' property from the state
    const { source } = state;

    // Determine if the dashboard is editable based on the presence of 'source'
    const isEditable = !!source;

    // Return an object containing the result
    return { isEditable };
  },
});

export const ConfigurationDocumentsSelector = selector({
	key: 'configuration-documents-selector-key',
	get: ({ get }) => {
		const { data } = get(UploadedEnvelopeDocsState);
		const configDocs = data.map(({ documentName, id, pages, totalPages }) => ({
			name: documentName,
			_id: id,
			pages,
			totalPages,
		}));

		return { documents: configDocs };
	},
});
