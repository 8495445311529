import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { API_URL, ENVELOPE_PURPOSE, MESSAGE } from 'constant';
import { useNetwork, useNotification, useUpdateDeviceInfo } from 'hooks';
import {
	configDocTitleState,
	useInvitePayload,
	useSetConfigDoc,
} from 'views/editor-dashboard';
import {
	IRecipientUser,
	IsEnableMerchantFlow,
	IsOriginalCopy,
	RecipientLocalState,
	RecipientsState,
	counterSecond,
	getColor,
	isSigningOrderEnabledState,
	recipientsSelector,
	resetColorsSelected,
	useRecipient,
} from 'views/reciepient-modal';
import { v4 } from 'uuid';
import { APP_ROUTES, IRecipient, newRecipient } from 'views';
import {
	EnvelopePurposeState,
	isShowSuccessInvitation,
	organizationDetailState,
	webComponentEnvelopeIdState,
} from 'states';
import { useNavigate, useParams } from 'react-router-dom';

import {
	DocumentPreparingStatus,
	IEnvelopeListDetails,
	ITemplateType,
	ConfigDocumentLoadingStatus,
	TemplateSourceState,
	UPLOAD_DOCS_MESSAGE,
	UploadedEnvelopeDocsState,
	templateTypeState,
	ActiveRecipientTabState,
	prepareDashboardSelector,
	prepareTypeState,
	newUploadFile,
	IsUndoModal,
} from '.';
const {
	FILE_UPLOADED,
	UPLOAD_FAILED,
	ONE_FILE_REQUIRED,
	ONE_NEED_TO_SIGN_REQUIRED,
} = MESSAGE;
const { SWAPPED_FAILED } = UPLOAD_DOCS_MESSAGE;

export const useUploadMultiDoc = () => {
	const setEnvelopeDocs = useSetRecoilState(UploadedEnvelopeDocsState);
	const [isLoaded, setIsLoaded] = useState(true);
	const envelopeId = useRecoilValue(webComponentEnvelopeIdState);

	const { successNotification, errorNotification } = useNotification();
	const { remove, formData } = useNetwork();
	const { id: paramId } = useParams();
	const setDocumentBeingPrepared = useSetRecoilState(DocumentPreparingStatus);

	const uploadDocuments = useCallback(
		async (
			payload: FormData,
			swap?: boolean,
			oldDocId?: string
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		): Promise<any> => {
			const id = paramId || envelopeId;
			let apiUrl = `${API_URL.PDF_UPLOAD}?id=${id}`;
			if (swap) {
				apiUrl = `${apiUrl}&oldDocId=${oldDocId}&swap=${swap}`;
			}
			const resp = await formData(apiUrl, payload);
			return resp;
		},
		[envelopeId, formData, paramId]
	);

	const uploadDoc = useCallback(
		async (payload: FormData): Promise<void> => {
			setEnvelopeDocs((prev) => ({
				...prev,
				isLoading: true,
			}));
			const resp = await uploadDocuments(payload);
			const { apiData } = resp ?? {};
			if (apiData) {
				const data = apiData?.[0];
				const { _id: id, name: documentName = '' } = data ?? {};
				const uploadDoc: IEnvelopeListDetails = {
					id,
					documentName,
					tabsCount: '0',
					pagesCount: data?.pages?.length ?? 0,
					pages: [],
					tabs: [],
					newUpload: true,
				};
				setEnvelopeDocs((prev) => ({
					...prev,
					isLoading: false,
					data: [...prev.data, uploadDoc],
				}));
				successNotification(FILE_UPLOADED);
				return;
			}
			errorNotification(apiData.message === "Encrypted Document" ? apiData.message : UPLOAD_FAILED);
			setEnvelopeDocs((prev) => ({
				...prev,
				isLoading: false,
			}));
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[uploadDocuments]
	);

	const swapDoc = useCallback(
		async (payload: FormData, oldDocId: string, index: number) => {
			setIsLoaded(false);
			setDocumentBeingPrepared(true);
			const uploadResponse = await uploadDocuments(payload, true, oldDocId);
			const { apiData: uploadedDoc, response: uploadedDocResponse } =
				uploadResponse;
			if (uploadedDocResponse.status === 200) {
				const { _id, pages, name } = uploadedDoc[0];
				setEnvelopeDocs((prev) => {
					const prevState = structuredClone(prev.data);
					const foundDoc = prevState[index];
					if (foundDoc) {
						prevState.splice(index, 1, {
							...foundDoc,
							documentName: name ?? '',
							id: _id,
							pages: pages,
						});
					}
					return { isLoading: prev.isLoading, data: prevState };
				});
			} else {
				errorNotification(uploadedDocResponse?.message ?? SWAPPED_FAILED);
			}
			setIsLoaded(true);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[uploadDocuments]
	);

	const deleteDoc = useCallback(
		async (id: string): Promise<void> => {
			await remove(`${API_URL.DOCUMENTS}/${id}`);
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[remove, successNotification]
	);

	return {
		uploadDoc,
		deleteDoc,
		swapDoc,
		isLoaded,
	};
};

export const usePrepareOverlay = () => {
	const setEnvelopeDocs = useSetRecoilState(UploadedEnvelopeDocsState);
	const { get, patch } = useNetwork({ updateState: false });
	const setConfigDocTitle = useSetRecoilState(configDocTitleState);
	const setRecipients = useSetRecoilState(RecipientsState);
	const setConfigDocLoadingStatus = useSetRecoilState(
		ConfigDocumentLoadingStatus
	);
	const setTemplateType = useSetRecoilState(templateTypeState);
	const setOrganization = useSetRecoilState(organizationDetailState);
	const setLocalRecipients = useSetRecoilState(RecipientLocalState);
	const setTemplateSource = useSetRecoilState(TemplateSourceState);
	const { multiDocHandler } = useSetConfigDoc();
	const setIsMerchantFlow = useSetRecoilState(IsEnableMerchantFlow);
	const setSignOrder = useSetRecoilState(isSigningOrderEnabledState);
	const { errorNotification } = useNotification();

	const fetchOverlayTemplate = useCallback(
		async (templateId: string) => {
			setConfigDocLoadingStatus('loading');

			// fetch overlay template
			const resp = await get(`${API_URL.TEMPLATE}/${templateId}`);
			const { apiData, response } = resp;
			if (response?.status === 200) {
				const {
					recipients,
					documents,
					name = 'Untitled',
					createdAt,
					type,
					source,
					whiteLabelInfo,
					reviewSign,
					signOrder,
				} = apiData.data;
				setConfigDocTitle({ name, createdAt });
				const allRecipients = recipients.map((recipient: IRecipient) => ({
					...recipient,
					color: recipient.colorCode,
				}));
				setIsMerchantFlow(reviewSign);
				setSignOrder(signOrder);
				setTemplateSource(source);
				setRecipients(allRecipients);
				const { envelopeList } = multiDocHandler(documents);
				const firstEmptyForm = {
					...newRecipient,
					color: getColor(),
					id: 'temp' + v4(),
				};
				// set white label data
				if (whiteLabelInfo?.whitelabel) {
					setOrganization(whiteLabelInfo);
				}
				setTemplateType(type as ITemplateType);
				setEnvelopeDocs({ isLoading: false, data: envelopeList });
				setLocalRecipients(
					allRecipients.length ? allRecipients : [firstEmptyForm]
				);
			}
			setConfigDocLoadingStatus('completed');
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[multiDocHandler]
	);

	const patchOverlaytemplate = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: any, templateId: string) => {
			const resp = await patch(`${API_URL.TEMPLATE}/${templateId}`, payload);
			const { apiData, response } = resp;
			const { recipients } = apiData.data;
			const allRecipients = recipients.map((recipient: IRecipient) => ({
				...recipient,
				color: recipient?.colorCode,
				id: recipient?._id,
			}));
			setRecipients(allRecipients);
			setLocalRecipients(allRecipients);
			if (response?.status === 200) {
				return true;
			} else {
				errorNotification(
					apiData?.message ?? 'Failed to start template configuration'
				);
				return false;
			}
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return { fetchOverlayTemplate, patchOverlaytemplate };
};

export const useSubmitprepareDashboard = () => {
	const { data: uploadDocList } = useRecoilValue(UploadedEnvelopeDocsState);
	const [isLoading, setIsLoading] = useState(false);
	const { id } = useParams();
	// local state
	const [timeoutApiCall, setTimeoutApiCall] = useState(false);
	const { errorNotification } = useNotification();

	const activeTab = useRecoilValue(ActiveRecipientTabState);
	const { isEditable } = useRecoilValue(prepareDashboardSelector);
	const { isNeedToSignRequired } = useRecoilValue(recipientsSelector);
	const templateType = useRecoilValue(templateTypeState);
	const prepareType = useRecoilValue(prepareTypeState);
	const localRecipients = useRecoilValue(RecipientLocalState);
	const isOriginalCopy = useRecoilValue(IsOriginalCopy);
	const isMerchantFlow = useRecoilValue(IsEnableMerchantFlow);
	const signOrder = useRecoilValue(isSigningOrderEnabledState);
	const webComponentEnvelopeId = useRecoilValue(webComponentEnvelopeIdState);
	const newUploadData = useRecoilValue(newUploadFile);
	const purpose = useRecoilValue(EnvelopePurposeState);
	const setIsUndoModal = useSetRecoilState(IsUndoModal);
	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);

	const paramId = id ?? webComponentEnvelopeId;

	const { saveLocalRecipients, patchRecipients } = useRecipient();
	const { patchOverlaytemplate } = usePrepareOverlay();
	const { patchDeviceInfo } = useUpdateDeviceInfo();
	const { handleSaveEnvelope } = useSetConfigDoc();
	const { saveTemplatePayload } = useInvitePayload({ envelopeId: paramId });
	const setInvitationSuccess = useSetRecoilState(isShowSuccessInvitation);

	const setPreparingDoc = useSetRecoilState(DocumentPreparingStatus);

	const navigate = useNavigate();
	const counterRef = useRef(0);

	const patchOverlayRecipientsPayload = useMemo(() => {
		const tempRecipient = structuredClone(localRecipients).map(
			(recipient: IRecipientUser) => {
				const { message, action, id, title, color, originalCopy, _id } =
					recipient;
				const data: { [key: string]: string | boolean | undefined } = {
					action,
					title: title?.trim(),
					colorCode: color,
					originalCopy: isOriginalCopy ? originalCopy : true,
					_id: _id,
				};
				if (id && !id.startsWith('temp')) {
					data._id = id;
				}
				if (message) {
					data.message = message.trim();
				}
				return data;
			}
		);
		const documentList = uploadDocList.map(({ id, originalTabs, tabs }) => ({
			document: id,
			tabs: originalTabs ?? tabs,
		}));

		return {
			recipients: tempRecipient,
			documents: documentList,
			reviewSign: isMerchantFlow,
			signOrder,
		};
	}, [
		localRecipients,
		uploadDocList,
		isMerchantFlow,
		signOrder,
		isOriginalCopy,
	]);

	const isReconfigureRequired = useMemo(() => {
		return newUploadData.length > 0;
	}, [newUploadData]);

	const handleSubmit = useCallback(
		/**
		 * validate and save the recipients
		 * @returns a promise if needed to use for loading symbol
		 */
		async (): Promise<void> => {
			/**
			 * Step 1: Check if at least one document has been uploaded
			 * */
			if (uploadDocList.length < 1) {
				setTimeoutApiCall(false);
				errorNotification(ONE_FILE_REQUIRED);
				return Promise.resolve();
			} else if (activeTab === 'selfsign') {
				/**
				 * Step 2: Check if the active tab is 'self' (Self-signing mode)
				 * */
				// setPreparingDoc(true);
				setIsLoading(true);
				// please hold this api untill all the docs have a min of one page loaded
				await patchRecipients(true, 'selfsign');
				setIsLoading(false);
				// setPreparingDoc(false)
				return; // Exit the function
			}

			/**
			 * Step 3: Only for correct screen: check that at least one pending recipient has need to sign action
			 * */
			if (!isNeedToSignRequired && isEditable === true) {
				setTimeoutApiCall(false);
				errorNotification(ONE_NEED_TO_SIGN_REQUIRED);
				return Promise.resolve();
			}

			const isSaveLocalRecipients =
				templateType !== 'overlay' ? saveLocalRecipients() : true;
			if (isSaveLocalRecipients) {
				setIsLoading(true);
				// over lay template create flow
				if (prepareType === 'overlay' && templateType === 'overlay') {
					// setPreparingDoc(true);
					return patchOverlaytemplate(
						patchOverlayRecipientsPayload,
						paramId ?? ''
					).then((status) => {
						if (status) {
							if (webComponentEnvelopeId) {
								return;
							}
							navigate(`/${APP_ROUTES.CONFIG_DOC}?templateId=${paramId}`);
						} else {
							setIsLoading(false);
							setTimeoutApiCall(false);
						}
					});
				} else if (templateType === 'basic') {
					// basic multisigner flow
					patchRecipients(true).then((successful) => {
						if (!successful) {
							setIsLoading(false);
							setTimeoutApiCall(false);
						}
					});
				} else {
					// overlay invite flow
					const shouldNavigate = isReconfigureRequired;
					const status = await patchRecipients(shouldNavigate);
					if (!shouldNavigate && status) {
						patchDeviceInfo('sender');
						await handleSaveEnvelope(saveTemplatePayload, paramId, true).then(
							(saveSuccessful) => {
								if (saveSuccessful) {
									setInvitationSuccess(true);
									resetColorsSelected();
								} else {
									setIsLoading(false);
									setTimeoutApiCall(false);
								}
							}
						);
					} else {
						setIsLoading(false);
						setTimeoutApiCall(false);
					}
				}
			} else {
				setTimeoutApiCall(false);
			}
			return Promise.resolve();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			patchOverlayRecipientsPayload,
			uploadDocList,
			activeTab,
			isNeedToSignRequired,
			isEditable,
			templateType,
			saveLocalRecipients,
			patchRecipients,
			prepareType,
			patchOverlaytemplate,
			paramId,
			webComponentEnvelopeId,
			isReconfigureRequired,
			patchDeviceInfo,
			handleSaveEnvelope,
			saveTemplatePayload,
		]
	);

	const handleSubmitting = useCallback(async () => {
		const isSaveLocalRecipients =
			templateType === 'overlay' ? saveLocalRecipients() : true;
		setTimeoutApiCall(false);
		if (
			uploadDocList.length > 0 &&
			isSaveLocalRecipients &&
			!isReconfigureRequired &&
			prepareType === 'envelope' &&
			templateType === 'overlay'
		) {
			if (purpose == ENVELOPE_PURPOSE.MULTISIGNAGREEMENT) {
				handleSubmit();
			} else {
				setIsUndoModal(true);
				setIsLoading(true);
				setTimeout(() => {
					if (timeoutApiCall) handleSubmit();
				}, counterSecond * 1000);
			}
		} else if (isSaveLocalRecipients) {
			setTimeoutApiCall(true);
		}
	}, [
		templateType,
		saveLocalRecipients,
		uploadDocList.length,
		isReconfigureRequired,
		prepareType,
		purpose,
		handleSubmit,
		setIsUndoModal,
		timeoutApiCall,
	]);

	const havingDocWithZeroPages = useMemo(() => {
		const docs = envelopeData.data;
		return docs.some(({ pagesCount }) => parseInt(pagesCount) === 0);
	}, [envelopeData.data]);

	useEffect(() => {
		if (counterRef.current === 1 && !havingDocWithZeroPages) {
			handleSubmitting().then(() => {
				setPreparingDoc(false);
				counterRef.current = 0;
			});
		}
	}, [havingDocWithZeroPages, envelopeData.data]);

	const handleProcessDocument = useCallback(() => {
		if (havingDocWithZeroPages) {
			setPreparingDoc(true);
			counterRef.current += 1;
		} else {
			handleSubmitting().then(() => {
				setPreparingDoc(false);
			});
		}
	}, [havingDocWithZeroPages]);
	return {
		handleSubmitting,
		handleSubmit,
		isLoading,
		setIsLoading,
		handleProcessDocument,
		timeoutApiCall,
		setTimeoutApiCall,
	};
};
