import { FC, useCallback, useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";
import { IsViewOnlyConfigState } from "states";
import { generateDocumentUrl } from "utils/get-document-url";
import { Loader } from "@storybook";
import { IFinalPage } from "hooks";

import { DropItemsState, IDropedItems, SelectedPreviewPageState, useSetConfigDoc } from "..";

interface IPreviewDocImage {
    page: IFinalPage;
    pageIndex: number;
    doc: {name: string, pages: IFinalPage[], _id: string};
    docIndex: number;
}

export const PreviewDocImage:FC<IPreviewDocImage> = ({page, pageIndex, doc, docIndex }) => {
    const [loading, setIsLoading] = useState(false);
    const [selectedPage, setSelectedPage] = useRecoilState(
		SelectedPreviewPageState
	);
    
    const readOnly = useRecoilValue(IsViewOnlyConfigState);
    const dropItems: IDropedItems[] = useRecoilValue(DropItemsState);
    const { handleDeletePage } = useSetConfigDoc();

    const handlePreviewClick = useCallback(
		(pageId: string) => {
			const configDocuments = document.getElementById(pageId);
			configDocuments?.scrollIntoView({ behavior: 'smooth' });
			setSelectedPage(pageId);
		},
		[setSelectedPage]
	);

    const handleRemoveImage = useCallback(
        (docId: string, docIndex: number, pageIndex: number) => {
          handleDeletePage(docId, docIndex, pageIndex);
        },
        [handleDeletePage]
      );

      const getCountPerPage = useCallback(
        (_id: string, docPageNo: number) => {
          return dropItems.reduce((countsPerPage, { documentId, pageNumber }) => {
            if (documentId === _id && pageNumber === docPageNo) {
              return countsPerPage + 1;
            }
            return countsPerPage;
          }, 0);
        },
        [dropItems]
      );


    if (loading) {
        return <div
          className="preview-document__item preview-document__image-loading">
            <Loader dimension={20} className="loader-blue" />
          </div>
    }  

    return (
        <div
          className={`preview-document__item ${
            selectedPage === page?._id ? "active" : ""
          }`}
          onClick={() => handlePreviewClick(page?._id)}
        >
          <img
            src={generateDocumentUrl(page?._id)}
            alt="images"
            className="preview-document__img"
            draggable={false}
            loading="lazy"
            onError={() => {
              setIsLoading(true);
            }}
            onLoad={() => setIsLoading(false)
            }
          />
          <div className="preview-document__layout-details">
            <span className="preview-document__page-number">
              Page {pageIndex + 1}
            </span>
            {!readOnly && doc.pages.length > 1 && <span
              onClick={() => handleRemoveImage(doc._id, docIndex, pageIndex)}
              className="preview-document__delete"
            >
              <i className="ri-delete-bin-5-line" />
            </span>}
            <span className="preview-document__page-number">
              Tabs {getCountPerPage(doc._id, pageIndex + 1)}
            </span>
          </div>
        </div>
    )
}