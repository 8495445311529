import { FC, Fragment, useCallback, useEffect, useMemo } from 'react';

/**
 * Recoil for state management
 * */
import { useRecoilValue, useRecoilState } from 'recoil';
/**
 * Views and Components used in the application
 * */
import {
	AddRecipientFooter,
	AddRecipientHeader,
	IsEnableFacialAuth,
	RecipientLocalState,
	RecipientsDragAndDrop,
	SigningOrderCheckBox,
	UploadDocuments,
	UploadedEnvelopeDocsState,
	prepareTypeState,
	templateTypeState,
	useRecipient,
	useScrollToLastRecipient,
	newUploadFile,
	IsOriginalCopy,
	ActiveRecipientTabState,
	totalTabs,
	multiPeopleTabs,
	prepareDashboardSelector,
	addRecipientMessage,
	IsEnableENotaryToggle,
	IsEnableMerchantFlow,
	TemplateSourceState,
	useSubmitprepareDashboard,
	IsUndoModal,
} from 'views';
/**
 * Constants and Assets for the application
 * */
import { ASSETS, ENVELOPE_PURPOSE, TEMPLATE_SOURCE } from 'constant';
/**
 * Storybook components for UI elements
 * */
import { ReactSwitch } from '@storybook';
import { IMessageBanner, MessageBanner } from '@storybook/banner';
/**
 * Custom Component for managing recipient tabs
 * */
import { RecipientTabs } from 'components';
import { UndoModal } from 'views/undo-modal';
import { ENV_STATE, EnvState, EnvelopePurposeState } from 'states';

import { AdditionalFlag } from '../additional-flag';
interface IProps {
	isFirstDisabled?: boolean;
	header?: string;
	className?: string;
}

export const AddRecipients: FC<IProps> = ({ header = '', className = '' }) => {
	const showNotary = false;

	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);
	const prepareType = useRecoilValue(prepareTypeState);
	const templateType = useRecoilValue(templateTypeState);
	const localRecipients = useRecoilValue(RecipientLocalState);	
	const newUploadData = useRecoilValue(newUploadFile);
	const env = useRecoilValue(EnvState);
	
	useScrollToLastRecipient();
	//paras : add facial toggle
	const [enableFacialAuth, setEnableFacialAuth] =
		useRecoilState(IsEnableFacialAuth);

	const [isENotaryToggle, setIsENotaryToggle] = useRecoilState(
		IsEnableENotaryToggle
	);
	const [isMerchantFlow, setIsMerchantFlow] = useRecoilState(IsEnableMerchantFlow);
	const [isOriginalCopy, setIsOriginalCopy] = useRecoilState(IsOriginalCopy);
	
	const { SINGLE_FILE_SVG } = ASSETS;

	const activeTab = useRecoilValue(ActiveRecipientTabState);
	const isUndoModal = useRecoilValue(IsUndoModal);
	const { isEditable } = useRecoilValue(prepareDashboardSelector);
	const [purpose, setPurpose] = useRecoilState(EnvelopePurposeState);
	const templateSource = useRecoilValue(TemplateSourceState)
	const { setOriginalCopyData } = useRecipient();

	const { data: uploadDocList } = envelopeData;

	const isReconfigureRequired = useMemo(() => {
		return newUploadData.length > 0;
	}, [newUploadData]);

	const {handleProcessDocument, handleSubmit, isLoading, setIsLoading, setTimeoutApiCall, timeoutApiCall} = useSubmitprepareDashboard()

	useEffect(() => {
		if (activeTab) {
			setPurpose((prev) => {
				if (prev === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT) {
					return prev;
				}else {
					return activeTab;
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeTab]);	

	const getPrimaryButtonLabel = useMemo(() => {
		if (templateType === 'overlay') {
			if (purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT && !isReconfigureRequired) {
				return 'Save & Close';
			}
			
			if (!isReconfigureRequired && prepareType === 'envelope' && purpose !== ENVELOPE_PURPOSE.MULTISIGNAGREEMENT) {
				return 'Send Invite';
			}
		}
	
		return 'Continue';
	}, [isReconfigureRequired, prepareType, purpose, templateType]);


	useEffect(() => {
		if (!isUndoModal && isLoading) {
			setTimeoutApiCall(true);
			setIsLoading(false);
		} else if (timeoutApiCall) {
			handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUndoModal, timeoutApiCall]);

	//paras : add method for facial toggle
	const handleFacialSwitch = useCallback((checked: boolean) => {
		setEnableFacialAuth(checked);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleEnableENotary = useCallback((value: boolean) => {
		setIsENotaryToggle(value);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selfSignerScreen = useMemo(() => {
		return (
			<Fragment>
				{(env === 'stage' || env === "preprod") && showNotary && (
					<AdditionalFlag
						handleChange={handleEnableENotary}
						value={isENotaryToggle}
						description={addRecipientMessage.eNotary.description}
						heading={addRecipientMessage.eNotary.heading}
						warning={addRecipientMessage.eNotary?.warning as IMessageBanner}
					/>
				)}
				<div className="self-sign__image-wrapper">
					<div>
						<img
							src={SINGLE_FILE_SVG}
							alt=""
							loading="lazy"
							width={120}
							height={120}
						/>
					</div>
					<div className="self-sign__image-heading">
						You are the only signer for the document
					</div>
					<div className="self-sign__image-desc">
						You will complete the entire signing process yourself.
					</div>
				</div>
			</Fragment>
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [SINGLE_FILE_SVG, env, handleEnableENotary, isENotaryToggle]);

	const handleOriginalCopySwitch = useCallback(
		(checked: boolean) => {
			setIsOriginalCopy(checked);
			if (checked) {
				const defaultSelectedId =
					localRecipients[0]?.id ?? localRecipients[0]?._id;
				setOriginalCopyData(defaultSelectedId ?? '');
			} else {
				setOriginalCopyData('');
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[localRecipients]
	);

	const handleEnableMerchantFlow = useCallback((checked: boolean) => {
		setIsMerchantFlow(checked)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const renderActiveTabChild = useMemo(() => {
		switch (activeTab) {
			case 'workflow':
				return (
					<Fragment>
						<div className="add-recipient-container__flags-wrapper">
							<AdditionalFlag
								handleChange={handleOriginalCopySwitch}
								value={isOriginalCopy}
								description={addRecipientMessage.originalCopy.description}
								heading={addRecipientMessage.originalCopy.heading}
								warning={addRecipientMessage.originalCopy?.warning}
							/>
							{(purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT || templateSource === TEMPLATE_SOURCE.ONBOARDING ) &&
								<AdditionalFlag
								handleChange={handleEnableMerchantFlow}
								value={isMerchantFlow}
								description={addRecipientMessage.reviewSigning.description}
								heading={addRecipientMessage.reviewSigning.heading}
								warning={addRecipientMessage.reviewSigning?.warning}
							/>}
							{(env === ENV_STATE.STAGE || env === ENV_STATE.PREPROD) && purpose !== ENVELOPE_PURPOSE.MULTISIGNAGREEMENT && (
								<AdditionalFlag
									handleChange={handleEnableENotary}
									value={isENotaryToggle}
									description={addRecipientMessage.eNotary.description}
									heading={addRecipientMessage.eNotary.heading}
									warning={addRecipientMessage.eNotary?.warning as IMessageBanner}
								/>
							)}
						</div>
						<SigningOrderCheckBox />
						<RecipientsDragAndDrop />
					</Fragment>
				);
			case 'selfsign':
				return selfSignerScreen;
			default:
				return (
					<Fragment>
						<SigningOrderCheckBox />
						<RecipientsDragAndDrop />
					</Fragment>
				);
		}
	}, [activeTab, env, handleEnableENotary, handleEnableMerchantFlow, handleOriginalCopySwitch, isENotaryToggle, isMerchantFlow, isOriginalCopy, purpose, selfSignerScreen, templateSource]);

	const allowedTabs = useMemo(() => {
		if (
			templateType === 'basic' &&
			prepareType === 'envelope' &&
			!isEditable &&
			purpose !== ENVELOPE_PURPOSE.MULTISIGNAGREEMENT
		) {
			return totalTabs;
		}
		return multiPeopleTabs;
	}, [isEditable, prepareType, purpose, templateType]);
	
	const renderRecipientSection = useMemo(() => {
		return (
			<RecipientTabs state={ActiveRecipientTabState} totalTabs={allowedTabs}>
				{renderActiveTabChild}
			</RecipientTabs>
		);
	}, [allowedTabs, renderActiveTabChild]);

	return (
		<div
			className="add-recipient-container"
			id="add-recipient__recipient-list-data"
		>
			<AddRecipientHeader header={header} />
			<div className="add-recipient-data-container">
				<UploadDocuments />
				{prepareType === 'envelope' && templateType === 'overlay' && (
					<div className="upload-documents">
						<MessageBanner
							message="For document swap both documents must have the same number of 
					pages for this action to proceed successfully."
							varient="warning"
						/>
					</div>
				)}
				<div
					className={`add-recipient__body ${className}`}
					id="add-recipient-body"
				>
					<div className="add-recipient__form-header-text">
						{'Add Recipients'}
						{/* this modal is hidden for now @Manish */}
						{(templateType === 'basic' ||
							(templateType === 'overlay' && prepareType === 'envelope')) && (
							<div className="add-recipient__form-header-toogle-wrapper">
								{/* paras : add facial toggle 	 */}
								<span>Enable Biometric Identification</span>
								<ReactSwitch
									id={''}
									checked={enableFacialAuth}
									handleChange={handleFacialSwitch}
								/>
							</div>
						)}
					</div>
					{renderRecipientSection}
				</div>
			</div>
			<UndoModal width={'960px'} setLoading={setIsLoading} />
			<AddRecipientFooter
				primaryBtnLabel={getPrimaryButtonLabel}
				handlePrimaryClick={handleProcessDocument}
				loading={isLoading}
				isDisabled={uploadDocList.length < 1}
			/>
		</div>
	);
};
