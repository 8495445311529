import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Modal } from '@storybook';
import { FaceLandmarkerResult } from '@mediapipe/tasks-vision';
import { ASSETS } from 'constant';
import { IsFooterLoaderBtn } from 'views/signing-dashboard';

import {
	IsFaceMeshLoadedState,
	ICameraPermissionONState,
	IsFacialModalOpen,
	FacialUserImageState,
	IFacialBioMetricDoneState,
} from './store';
import { CameraSettings, CanvasWrapper, LoadingSection } from './components';
import { useFace } from './hooks';
import { FACIAL_MESSAGES } from './constant';

import './facial-biometrics.scss';

export const FacialBiometricModal = () => {
	// Assests
	const { SUCESS_IMG } = ASSETS;
	const screenWidth = 480;
	const captureRef = useRef<NodeJS.Timeout | null>(null);

	const [capture, setCapture] = useState(false);
	const blinkCountRef = useRef<number | null>(0)
	//Recoils -
	const setFacialBioMetricDone = useSetRecoilState(IFacialBioMetricDoneState);
	const [isModalOpen, setIsModalOpen] = useRecoilState(IsFacialModalOpen);
	const loaded = useRecoilValue(IsFaceMeshLoadedState);
	const cameraPermission = useRecoilValue(ICameraPermissionONState);
	const setFacialImg = useSetRecoilState(FacialUserImageState);
	const setIsLoaded = useSetRecoilState(IsFooterLoaderBtn);

	const { start, isFaceVisible } = useFace();
	const videoBlendShapes = document.getElementById('video-blend-shapes');
	const video = document.getElementById('Esign-webcam') as HTMLVideoElement;
	const canvasElement = document.getElementById(
		'Esign-output_canvas'
	) as HTMLCanvasElement;

	// Local states-
	const [hasCalledPatch, setHasCalledPatch] = useState(false);
	const [blinkCounts, setBlickCounts] = useState<number>(0);

	// Refs
	const allowBlinkRef = useRef(true);

	// // Arun Kumar : Function for Close Modal
	const handleCloseModal = useCallback(() => {
		setIsModalOpen((prev: boolean) => !prev);
		setIsLoaded(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// // Arun Kumar:  Function taking image of user while doing facial biometrics
	const getUserImage = useCallback(() => {
		const canvas = document.getElementById(
			'Esign-output_canvas'
		) as HTMLCanvasElement;
		const video = document.getElementById('Esign-webcam') as HTMLVideoElement;
		canvas
			?.getContext('2d')
			?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
		return canvas?.toDataURL('image/png');
	}, []);

	// canvas
	const renderCanvas = useMemo(() => {
		return <CanvasWrapper />;
	}, []);

	// Arun Kumar : Function for closing camera
	const handleCloseCamera = async () => {
		const videoElement = document.getElementById('Esign-webcam');
		if (videoElement instanceof HTMLVideoElement) {
			const stream = videoElement.srcObject;

			// Check if the stream exists
			if (stream instanceof MediaStream) {
				// Get all tracks from the stream
				const tracks = stream.getTracks();

				// Stop each track
				tracks.forEach((track: { stop: () => void }) => {
					track.stop();
				});

				// Clear the video element source object
				videoElement.srcObject = null;
			}
		}
	};

	const captureImage = useCallback(() => {
		setTimeout(() => {
			const img = getUserImage();
			setFacialImg(img.split(',')[1] ?? '');
		}, 500);
		captureRef.current = setTimeout(() => {
			setCapture(true)
		}, 1000);
	}, [getUserImage, setFacialImg])

	// function for future to calculate blink count
	const onResults = useCallback((results?: FaceLandmarkerResult) => {
		try {
			const eyeBlinkLeft = results?.faceBlendshapes?.[0]?.categories.find(
				(cat) => cat?.categoryName === 'eyeBlinkLeft'
			);
			const eyeBlinkRight = results?.faceBlendshapes?.[0]?.categories.find(
				(cat) => cat?.categoryName === 'eyeBlinkRight'
			);
			const thresholdRatio = 0.5;
			if (
				(eyeBlinkLeft?.score && eyeBlinkLeft?.score >= thresholdRatio) ||
				(eyeBlinkRight && eyeBlinkRight?.score >= thresholdRatio)
			) {
				if (allowBlinkRef.current) {
					allowBlinkRef.current = false;
					setBlickCounts((prev) => prev + 1);
					setTimeout(() => {
						allowBlinkRef.current = true;
					}, 400);

					if (blinkCountRef.current != null) {
						blinkCountRef.current += 1;
					}

					if (blinkCountRef.current === 2) {
						blinkCountRef.current = null;
						captureImage();
					}
				}
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.warn('error', error);
		}
	}, [captureImage]);

	useEffect(() => {
		// Calling functiin to stream mask and video
		start({
			video,
			canvas: canvasElement,
			blendShapes: videoBlendShapes,
			onResults,
		});
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cameraPermission, canvasElement, video, videoBlendShapes]);

	const renderLivenessCounts = useMemo(() => {
		if (loaded) {
			if (!isFaceVisible) {
				return (
					<div className="Esign-facial__info-message">
						{FACIAL_MESSAGES.CENTER_FACE}
					</div>
				)
			}
			switch (blinkCounts) {
				case 0:
					return (
						<div className="Esign-facial__info-message">
							{FACIAL_MESSAGES.BLINK_TWICE}
						</div>
					);
					break;
				case 1:
					return (
						<div className="Esign-facial__info-message">
							{FACIAL_MESSAGES.BLINK}
						</div>
					);
					break;
				default:
					return <div className="Esign-facial__info-message"></div>;
			}
		}
		return <></>;
	}, [blinkCounts, loaded, isFaceVisible]);

	useEffect(() => {
		if (!isFaceVisible) {
			if (captureRef.current) {
				clearTimeout(captureRef.current);
			}
			setCapture(false)
		}
	}, [isFaceVisible])

	useEffect(() => {
		if (isFaceVisible && !capture && blinkCounts >= 2) {
			captureImage();
		} else if (isFaceVisible, capture && blinkCounts >= 2 && !hasCalledPatch) {
			// Call patchDeviceInfo only once when blinkCounts is 2 and hasn't been called before
			setHasCalledPatch(true);
			// Arun kumar: Calling these function after if blink count is 2 after 2 second
			setTimeout(async () => {
				setFacialBioMetricDone(true);
				handleCloseCamera();
				handleCloseModal();
			}, 1000);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFaceVisible, capture])
	
	const renderInformations = useMemo(() => {
		if (blinkCounts >= 2) {
			if (isFaceVisible && !capture) {
				return (
					<div className='Esign-facial__info-message'>{FACIAL_MESSAGES.PROCESSING}</div>
				)
			}

			if (!isFaceVisible || !capture) {
				return <></>
			}

			if (!hasCalledPatch) {
				return <></>
			}
			return (
				<img
					className="Esign-facial__success-gif"
					src={SUCESS_IMG}
					alt="succes-gif"
				/>
			);
		}
		if (!loaded && cameraPermission) {
			return <LoadingSection />;
		}
		if (!cameraPermission) {
			return <CameraSettings />;
		}
		return <></>;
	}, [loaded, capture, blinkCounts, cameraPermission, hasCalledPatch, SUCESS_IMG, isFaceVisible]);

	return (
		<div>
			<Modal
				className="create-sign-modal"
				isOpen={isModalOpen}
				modalName=""
				showCloseBtn={false}
				isStopOutsideClick={false}
				closeModal={handleCloseModal}
			>
				<div className="Esign-facial__wrapper">
					<div
						style={{
							position: 'relative',
							background: 'white',
							marginBottom: '10px',
							marginLeft: '10px',
						}}
					>
						<video
							id="Esign-webcam"
							className="Esign-video"
							autoPlay
							playsInline
							controls={false}
							style={{
								display: loaded ? 'block' : 'none',
							}}
						></video>
						{renderCanvas}
						<div
							className="Esign-facial__info-wrapper"
							style={{ width: screenWidth < 480 ? screenWidth : '100%' }}
						>
							{renderInformations}
							{renderLivenessCounts}
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};
