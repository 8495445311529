import { IFinalPage, IRealtimeDocument } from "hooks";
import { atomFamily, selector  } from "recoil";
import { IEnvelopeListDetails, UploadedEnvelopeDocsState } from "views";

export const UploadedDocumentFamily = atomFamily({
    key: 'uploaded-document-pages-state-family-key',
    default: {},
});

export const DocumentPagesSelector = selector({
  key: "document-page-selector-key",
  get: ({ get }) => {
    const documentsState = get(UploadedEnvelopeDocsState); // O(1)
    const docs = documentsState.data as IEnvelopeListDetails[];

    const mainPages: IFinalPage[] = [];
    const data: { [key: string]: IRealtimeDocument } = {};
    const pageIdMap: { [key: string]: string } = {};

    // Flatten page IDs and prepare the data object in one pass
    docs.forEach((doc: IEnvelopeListDetails) => { // O(n)
      doc.pages.forEach((pageId: string) => {
        pageIdMap[pageId] = doc.id;
        if (!data[doc.id]) {
          data[doc.id] = { _id: doc.id, name: doc.documentName, pages: [] };
        }
      });
    });

    // Fetch and process each page individually
    Object.keys(pageIdMap).forEach((pageId) => {
      const pages = get(UploadedDocumentFamily(pageId)) as { [key: string]: IFinalPage };
      const page = pages[pageId];
      if (page) {
        mainPages.push(page);
        const docId = pageIdMap[pageId];
        if (data && docId && data[docId]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (data as any)[docId].pages.push(page);
        }
      }
    });

    const sidebarDocs = Object.values(data); // O(k)
    return { mainPages: mainPages ?? [], sidebarDocs: sidebarDocs ?? [] }; // O(1)
  },
});