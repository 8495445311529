import type { IRefs } from '../types';

import { useEffect } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ConfigDocumentLoadingStatus } from 'views/prepare-dashboard';

import { DropItemsState, redoDisabledState, undoDisabledState } from '../state';

export const useUndoRedo = (refs: IRefs) => {
	const dropItems = useRecoilValue(DropItemsState);
	const [undoDisabled, setUndoDisabled] = useRecoilState(undoDisabledState);
	const [redoDisabled, setRedoDisabled] = useRecoilState(redoDisabledState);
	const documentsLoadingStatus = useRecoilValue(ConfigDocumentLoadingStatus);
	const { undoPointer, changes, isUndoRedo } = refs;
	useEffect(() => {
		if(dropItems.length > 0) {
			const lastItem = dropItems[dropItems.length-1];
			if(lastItem && Object.keys(lastItem.metadata).length > 0) {
				if (
					!isUndoRedo.current &&
					changes.current.length !== 0 &&
					!isEqual(dropItems, changes.current[undoPointer.current])
				) {
					if (undoDisabled) {
						setUndoDisabled(false);
					}
					if (!redoDisabled) {
						setRedoDisabled(true);
					}
					const modified = cloneDeep(dropItems);
					const lastItem = modified.pop();
					if (lastItem) {
						//@ts-ignore
						lastItem['metadata'] = {};
						modified.push(lastItem);
					}
					//Check if the only change is metadata property added
					if (isEqual(changes.current[undoPointer.current], modified)) {
						changes.current = [
							...changes.current.slice(0, undoPointer.current),
							cloneDeep(dropItems),
						];
					} else {
						undoPointer.current = undoPointer.current + 1;
						changes.current = [
							...changes.current.slice(0, undoPointer.current),
							cloneDeep(dropItems),
						];
					}
				}
				
			}
		}
		isUndoRedo.current = false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dropItems]);
	
	useEffect(()=>{
		if (
			!isUndoRedo.current &&
			documentsLoadingStatus === "loading" &&
			changes.current.length === 0
		) {
			changes.current = [...changes.current, cloneDeep(dropItems)];
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])
};
