import { useMemo } from "react";

import { useRecoilValue } from "recoil";
import {
  ActionComp,
  DocumentTable,
  IEnvelopeListDetails,
  IUploadRowData,
  UploadDocHeader,
  UploadedEnvelopeDocsState,
} from "views";

export const UploadedFiles = () => {
  const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);

  const { data: addedFiles } = envelopeData;

  const renderRows = useMemo(() => {
    const rows: IUploadRowData[] = [];
    addedFiles.forEach((doc: IEnvelopeListDetails, index: number) => {
      if (doc) {
        let row = {} as IUploadRowData;
        UploadDocHeader.forEach(({ format, key }) => {
          if (format === "string" && key === "index") {
            return (row[key] = index + 1);
          }
          if (format === "jsx" && key === "actions") {
            return (row[key] = <ActionComp doc={doc} index={index} />);
          }
          if (format === "jsx" && key === "pagesCount") {
            const pageCount = parseInt(doc[key]);
            const value = pageCount === 0 ? <div>Loading...</div> : <div>{pageCount}</div>;
            return row[key] = value;
          }
          return (row = {
            ...row,
            [key]: doc[key as keyof IEnvelopeListDetails],
          });
        });
        rows.push(row);
      }
    });
    return rows;
  }, [addedFiles]);

  return (
    <div className="uploaded-files">
      {renderRows?.length > 0 && (
        <DocumentTable rows={renderRows} column={UploadDocHeader} />
      )}
    </div>
  );
};
