import type { IField, IFields, IOption, ITabMenu, ITabsConfiguration, ITabValidationField } from "./types";

import { selector } from "recoil";
import { capitalize } from "utils";

import {
  CustomTabState,
  MenuTabState,
  QuestionaireTabState,
  SelectedConfigNode,
} from "./state";
import { handleInvestorsQuestionnair, menuItemIcon } from "./utils";

export const ConfigTabMenuState = selector({
  key: "config-tab-menu-state-key",
  get: ({ get }) => {
    const { configuration, tabs = {} } = get(MenuTabState);

    const customField = get(CustomTabState);
    const questionaireTabs = get(QuestionaireTabState);

    const questionaireField = handleInvestorsQuestionnair(questionaireTabs);
    const activeNode = get(SelectedConfigNode);

    // nodes master  configuration fields    
    const configObj = {} as ITabsConfiguration;
    Object.keys(configuration ?? {}).forEach((key) => {
      const configArr = (configuration as ITabsConfiguration)[key as keyof ITabsConfiguration].map((config) => {
        if (config.type === "select") {
          const newOptions = config.options?.map((option) => ({
            label: option,
            value: option,
          }));
          const validationField = config.validationField;
         
          const obj: ITabValidationField = {};
          if(validationField) {
            Object.keys(validationField).forEach((validationKey: string) => {
              if(validationField) {
                
                const fieldValue = validationField[validationKey] as IField;
          
                if (typeof fieldValue !== 'string' && fieldValue.type === 'select' && Array.isArray(fieldValue.options)) {
                  const newOptions = fieldValue.options.map((val) => {
                        if (typeof val === 'string') {
                            return { label: val, value: val } as IOption;
                        } else {
                            return val as IOption;
                        }
                    });
                   
                  const defaultValue = ((typeof fieldValue.default) === 'string' ? { label: fieldValue.default, value: fieldValue.default } : fieldValue.default) as IOption;
                  const configObj: IField = { ...fieldValue, options: newOptions, default: defaultValue };
                  obj[validationKey] = configObj;
                }
               
              }
            })
          }
          const defaultValue = { label: config.default, value: config.default };
          return { ...config, options: newOptions, default: defaultValue, validationField: obj };
        }
        return config;
      });
      (configObj)[key as keyof ITabsConfiguration] = configArr as IField[];
    });
    
    const nodesMasterConfiguration = configObj ?? {};
    // configuration fields array for the selected field node
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selectedFieldsNodes: any = [];

    const fieldType = activeNode?.validationType ? 'text' : activeNode?.fieldType;
    (activeNode && fieldType ? nodesMasterConfiguration[fieldType] : []).forEach(
      (configField) => {
        if (
          activeNode?.fieldType === "text" &&
          activeNode.isEditable === false 
        
        ) {
          if (
            configField.key === "editText" ||
            configField.key === "readOnly"
          ) {
            return;
          }
          selectedFieldsNodes.push(configField);
          return;
        }
        selectedFieldsNodes.push(configField);
      }
    );

    // sidebar menu list calculations has been done in here
    let menuTabs: IFields = {
      custom: {
        heading: "Custom Fields",
        items: customField.map(
          ({
            createdAt,
            label,
            name,
            source,
            type,
            updatedAt,
            value,
            _id,
            key,
            id,
            fieldType,
            isEditable,
            isNew,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }: ITabMenu | any) => ({
            createdAt,
            icon: menuItemIcon(fieldType ?? type),
            label,
            key: key ?? name,
            source,
            fieldType: fieldType ?? type,
            updatedAt,
            value,
            id: id ?? _id,
            isEditable,
            isNew: isNew ?? false
          })
        ),
      },
      questionnaire: {
        heading: "Questionnaire Fields",
        items: questionaireField.map(({
          createdAt,
          label,
          name,
          source,
          type,
          updatedAt,
          value,
          _id,
          key,
          id,
          fieldType,
          isEditable,
          questionType,
          isNew,
          cell,
          choices
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }: ITabMenu | any) => ({
          createdAt,
          icon: menuItemIcon(fieldType ?? type),
          label,
          key: key ?? name,
          source,
          fieldType: fieldType ?? type,
          updatedAt,
          value,
          id: id ?? _id,
          isEditable,
          isNew: isNew ?? false,
          questionType: questionType ?? fieldType ?? type,
          cell,
          choices

        }))
      }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const menu: any = {};
    Object.keys(tabs).forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tabItems: any[] = [];
      Object.keys(tabs[key]).forEach((tabItem) => {
        const {
          label,
          name,
          source,
          type: fieldType,
          isEditable,
        } = tabs[key][tabItem];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const item: any = {
          icon: menuItemIcon(fieldType),
          label,
          key: name,
          source,
          fieldType,
        };
        if (isEditable !== undefined) {
          item.isEditable = isEditable;
        }
        /**
         * 
         * */ 
        if (source === "standard" && name === "date") {
          return;
        }
        tabItems.push(item);
      });
      menu[key] = {
        heading: `${capitalize(key)} Fields`,
        items: tabItems,
      };
    });
    menuTabs = { ...menuTabs, ...menu } as IFields;
    // this will provide the keys ie state keys of all the edit fields of the selected node.

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const metaKeys: { [key: string]: any } = {};
    (activeNode ? nodesMasterConfiguration[activeNode.fieldType] : [])?.forEach(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (field: any) => (metaKeys[field.key] = field.default)
    );

    //  this will manage the active menu tab in the left sidebar
    return {
      menuTabs,
      nodesMasterConfiguration,
      selectedFieldsNodes,
      metaKeys,
    };
  },
});
