import { useCallback, useEffect, useMemo } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IFinalPage, IRealtimeDocument } from 'hooks';
import { Collapsible } from '@storybook';
import { ASSETS } from 'constant';
import { DocumentPagesSelector } from 'states/documents';

import { SelectedPreviewPageState } from '..';

import { PreviewDocImage } from './preview-doc-image';

const { DOC_MISSING_SVG } = ASSETS;
export const PreviewDocument = () => {

	const setSelectedPage = useSetRecoilState(
		SelectedPreviewPageState
	);
	const { sidebarDocs } = useRecoilValue(DocumentPagesSelector);


	useEffect(() => {
		const defaultPreviewSelectedPage = sidebarDocs[0]?.pages[0]?._id;
		if (defaultPreviewSelectedPage) {
			setSelectedPage(defaultPreviewSelectedPage);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const renderLayouts = useCallback(
		(doc: IRealtimeDocument, docIndex: number) => {
			const { pages = [] } = doc ?? {};
			return pages.map((page: IFinalPage, index: number) => (
				<PreviewDocImage
					page={page}
					pageIndex={index}
					docIndex={docIndex}
					doc={doc}
					key={page?._id}
				/>
			));
		},
		[]
	);

	const PreviewDocList = useMemo(() => {
		return sidebarDocs.length ? (
			sidebarDocs.map((document: IRealtimeDocument, index: number) => {
				return (
					<Collapsible header={document.name} key={index}>
						{renderLayouts(document, index)}
					</Collapsible>
				);
			})
		) : (
			<div className="preview-document__placeholder">
				<img src={DOC_MISSING_SVG} alt="" loading="lazy" />
				<span>No documents to show preview</span>
			</div>
		);
	}, [renderLayouts, sidebarDocs]);

	return <div className="preview-document">{PreviewDocList}</div>;
};
