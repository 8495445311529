import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

export const useFirebaseInit = () => {
	const firebaseConfig = {
		apiKey: "AIzaSyAX-qEF0bFHDOXNtyo6xeLvEKVMS8niAXs",
		authDomain: "glossy-fastness-305315.firebaseapp.com",
		databaseURL: "https://glossy-fastness-305315-default-rtdb.firebaseio.com",
		projectId: "glossy-fastness-305315",
		storageBucket: "glossy-fastness-305315.appspot.com",
		messagingSenderId: "952484154781",
		appId: "1:952484154781:web:470d88056966670a247374",
	};

	// Initialize Firebase
	const app = initializeApp(firebaseConfig);
	const database = getDatabase(app);

	return { database };
};
