import type { IDropedItems, IFieldNodeType } from '../types';

import { useCallback, useState } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	IRecipientUser,
	RecipientLocalState,
	RecipientsState,
	SelectedRecipientState,
	getColor,
	regexNeedsToSign,
	newRecipient,
	IEnvelopeListDetails,
	UploadedEnvelopeDocsState,
	prepareTypeState,
	templateTypeState,
	IsOriginalCopy,
	ActiveRecipientTabState,
	PrepareDashboardState,
	IsEnableFacialAuth,
	IsEnableENotaryToggle,
	IsEnableMerchantFlow,
	isSigningOrderEnabledState,
	VALIDATION_TYPES,
	ConfigDocumentLoadingStatus,
} from 'views';
import { IAuthUserAuthType, IDocument, ITab, SenderDetailsState } from 'views/signing-dashboard';
import { useDocument, useNetwork, useNotification, useQueryParams } from 'hooks';
import { API_URL, ENVELOPE_PURPOSE, MESSAGE, TAB_SOURCE } from 'constant';
import { GetQueryParams } from 'utils';
import { APP_ROUTES } from 'views/routes';
import { v4 } from 'uuid';
import { EnvelopePurposeState, organizationDetailState } from 'states';
import {
	isShowSelfSignDocScreen,
	isShowSuccessInvitation,
	webComponentEnvelopeIdState,
} from 'states/web-component-state';

import {
	ActiveMenuTabState,
	CustomTabState,
	DropItemsState,
	EditorNodesLoadedState,
	MenuTabState,
	TabConfigurationState,
	QuestionaireTabState,
	configDocTitleState,
} from '../state';

/**
 * this will be used to set the data in the state
 * **/
export const useSetConfigDoc = () => {
	const [dropItems, setDropItems] = useRecoilState(DropItemsState);
	const setConfigDocTitle = useSetRecoilState(configDocTitleState);
	const setConfigDocLoadingStatus = useSetRecoilState(ConfigDocumentLoadingStatus);
	const setMenuTab = useSetRecoilState(MenuTabState);
	const setCustomFields = useSetRecoilState(CustomTabState);
	const setQuestionaireFields = useSetRecoilState(QuestionaireTabState);
	const setActiveMenuTab = useSetRecoilState(ActiveMenuTabState);
	const setSelectedRecipient = useSetRecoilState(SelectedRecipientState);
	const [localRecipients, setLocalRecipients] =
		useRecoilState(RecipientLocalState);
	const setRecipients = useSetRecoilState(RecipientsState);
	const setUploadedEnvelopeDocs = useSetRecoilState(UploadedEnvelopeDocsState);
	const setSenderDetails = useSetRecoilState(SenderDetailsState);
	const setTemplateType = useSetRecoilState(templateTypeState);
	const prepareType = useRecoilValue(prepareTypeState);
	const setIsEditorNodesLoaded = useSetRecoilState(EditorNodesLoadedState);
	const setIsOriginalCopy = useSetRecoilState(IsOriginalCopy);
	const setPurpose = useSetRecoilState(EnvelopePurposeState);
	const setActiveTab = useSetRecoilState(ActiveRecipientTabState);
	const setPrepareDashboard = useSetRecoilState(PrepareDashboardState);
	const setEnableFacialAuth = useSetRecoilState(IsEnableFacialAuth);
	const setIsEnableENotary = useSetRecoilState(IsEnableENotaryToggle);
	const setShowSelfSignDoc = useSetRecoilState(isShowSelfSignDocScreen);
	const setInvitationSentStatus = useSetRecoilState(isShowSuccessInvitation);
	const setUserAuthType = useSetRecoilState(IAuthUserAuthType);
	const setOrganization = useSetRecoilState(organizationDetailState);
	const setConfiguration = useSetRecoilState(TabConfigurationState);

	const webComponentEnvelopeId = useRecoilValue(webComponentEnvelopeIdState);

	const { errorNotification, successNotification } = useNotification();
	const { API_ERROR_MESSAGE, TEMPLATE_SAVED_MESSAGE } = MESSAGE;
	const [isLoading, setIsLoading] = useState(false);

	const { get, patch, remove, apiHostExists } = useNetwork();
	const templateId = GetQueryParams('templateId');
	const { id: paramId } = useParams();
	const location = useLocation();
	const navigate = useNavigate();
	const { getReadOnlyParams } = useQueryParams({ updateState: false });
	const readOnly = getReadOnlyParams();
	const  setIsMerchantFlow = useSetRecoilState(IsEnableMerchantFlow);
	const setSigningOrderEnabled = useSetRecoilState(isSigningOrderEnabledState);

	const { handleUpdateDocumentsFamily } = useDocument()

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleUpdateContainerDimentions = useCallback((documents: any) => {
		setDropItems((prev) => {
			const newState = structuredClone(prev).map((nodeItem: IDropedItems) => {
				const { documentId, pageNumber, position, metadata } = nodeItem;
				const foundDocument = documents.find(
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					({ document }: any) => {
						return document._id === documentId;
					}
				);
				if (foundDocument) {
					const page = foundDocument.document.pages[pageNumber - 1];
					const dropContainer = document.getElementById(page._id);
					if (dropContainer) {
						const container = dropContainer.getBoundingClientRect();
						const { width: containerWidth, height: containerHeight } =
							container;
						const { xLeft, xRight, yBottom, yTop } = position;
						const elementsWidth = `${(xRight - xLeft) * containerWidth}px`;
						const elementsHeight = `${(yBottom - yTop) * containerHeight}px`;
						return {
							...nodeItem,
							metadata: {
								...metadata,
								width: elementsWidth,
								height: elementsHeight,
							},
							containerWidth: container?.width,
							containerHeight: container?.height,
						};
					}
				}
				return nodeItem;
			});
			setTimeout(() => {
				setIsEditorNodesLoaded(true);
			}, 1000);
			return newState;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const multiDocHandler = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(documents: any, recipient = '') => {
			const configuredDropItemsArray: IDropedItems[] = [];
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const multiDocDataArray: any = [];
			const envelopeList: IEnvelopeListDetails[] = [];

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			documents.forEach((doc: any) => {
				const { tabs, document } = doc;
				const tabsIdList: string[] = [];
				const configuredDropItems = tabs.map(
					({
						_id,
						name,
						label,
						position,
						type,
						fieldType,
						source,
						icon,
						value,
						pageNumber,
						metadata,
						signature,
						recipientId, 
						questionType,
						cell
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
					}: any) => {
						tabsIdList.push(_id);
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						const data: any = {
							icon,
							label,
							key: name,
							source,
							fieldType: type,
							id: _id,
							pageNumber,
							position: {
								xLeft: position.xLeft,
								xRight: position.xRight,
								yTop: position.yTop,
								yBottom: position.yBottom,
							},
							value,
							documentId: doc.document._id,
							signature,
							recipient: recipient || recipientId,
							questionType: questionType || fieldType || type,
							cell
						};
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						const metadataObj: any = {};
						Object.keys(metadata).forEach((key) => {
							if (key !== _id) {
								if (key === 'fontSize' || key === 'fontFamily') {
									metadataObj[key] = {
										label: metadata[key],
										value: metadata[key],
									};
									return;
								}
								metadataObj[key] = metadata[key];
							}
						});
						data.metadata = metadataObj;
						return data;
					}
				);
				const originalTabs = tabs.map((item: ITab) => {
					const tabItem: Partial<ITab> = {
						name: item.name,
						label: item.label,
						type: item.type,
						source: item.source,
						position: {
							xLeft: item.position.xLeft,
							xRight: item.position.xRight,
							yTop: item.position.yTop,
							yBottom: item.position.yBottom,
						},
						pageNumber: item.pageNumber,
						icon: item.icon,
						value: item.value,
						index: item.index,
						metadata: item.metadata,
						questionType: item.questionType || item.fieldType || item.type,
						cell: item.cell,
					};
					if (item.recipientId) {
						tabItem.recipientId = item.recipientId;
					}
					return tabItem;
				});

				const pageIds = document.pages.map((page: IDocument) => page._id);
				const docDetails: IEnvelopeListDetails = {
					id: document._id,
					tabsCount: tabs.length,
					pages: pageIds,
					pagesCount: pageIds.length,
					documentName: document.name,
					tabs: tabsIdList,
					originalTabs: originalTabs,
					totalPages: document.pages
				};
				envelopeList.push(docDetails);
				configuredDropItemsArray.push(...configuredDropItems);

				// const { pages } = doc.document;
				// const multiDocData = {
				// 	...doc.document,
				// 	// images: pages || [],
				// };
				multiDocDataArray.push(doc.document);
			});
			const allDropItems: IDropedItems[] = configuredDropItemsArray.flat() ?? [];
			setTimeout(() => {
				handleUpdateContainerDimentions(documents);
			}, 3000);
			return {
				allDropItems,
				multiDocDataArray,
				envelopeList,
			};
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[prepareType, readOnly]
	);

	const generateRecipients = useCallback(
		(recipients?: IRecipientUser[]): IRecipientUser[] => {
			if (!recipients) {
				return [];
			}
			return recipients.map((item: IRecipientUser) => ({
				...item,
				id: item._id,
				role: item?.role || 'signer',
				action: item?.action || 'Needs to sign',
				color: item?.colorCode || getColor(),
				isPrivateMessageEnabled: !!item?.message,
				recipientLocalType: item.type,
			})) as IRecipientUser[];
		},
		[]
	);

	const assignColorFromLocalRecipient = useCallback(
		(_recipients?: IRecipientUser[]): IRecipientUser[] => {
			if (!_recipients) {
				return [];
			}
			return _recipients.map((recipient: IRecipientUser) => {
				const selectedLocalRecipient = localRecipients.find(
					(item: IRecipientUser) =>
						item.email === recipient.email ||
						item.id === recipient.id ||
						item._id === recipient._id
				);
				if (selectedLocalRecipient) {
					return {
						...recipient,
						...(selectedLocalRecipient
							? { color: selectedLocalRecipient.color }
							: {}),
					};
				}
				return recipient;
			});
		},
		[localRecipients]
	);

	const handleUpdateRecipientsAndTabs = useCallback(
		(allDropItems: IDropedItems[], recipients: IRecipientUser[]) => {
			const firstEmptyForm = {
				...newRecipient,
				color: getColor(),
				id: 'temp' + v4(),
			};
			const allDropNodesWithSigners = recipients.length === 0 ? allDropItems : allDropItems.filter((item) => {
				if (item.source === TAB_SOURCE.COMMON) {
					return true;
				}
				const associatedRecipient = item.recipient;
				const recipient = recipients.find(
					(recipient) => recipient._id === associatedRecipient
				);

				return recipient && recipient.action.match(regexNeedsToSign);
			});			
			setLocalRecipients(recipients.length ? recipients : [firstEmptyForm]);
			setDropItems(allDropNodesWithSigners as IDropedItems[]);
		},
		[setDropItems, setLocalRecipients]
	);

	const setEnvelopeValues = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(recipientsList: IRecipientUser[],documents: any = []) => {
			const recipients = generateRecipients(
				assignColorFromLocalRecipient(recipientsList)
			);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const allTabs = documents.flatMap((document: any) => document.tabs || []);

			const isTabsWithoutRecipients = (allTabs as ITab[])
				.filter((tab) => tab.source !== TAB_SOURCE.COMMON)
				.every((tab) => tab.recipientId == null);

			let recipientId = '';
			const tempSelectedRecipient = recipients.find(
				(item) => !!item.action.match(regexNeedsToSign)
			);
			if (recipients?.length && isTabsWithoutRecipients) {
				/**
				 * first signer recipient
				 */
				recipientId = tempSelectedRecipient?.id || '';
			}
			if (tempSelectedRecipient) {
				// set the first signer recipient as the selected one
				setSelectedRecipient([tempSelectedRecipient]);
			}
			setRecipients(recipients);

			const {
				allDropItems,
				envelopeList,
			} = multiDocHandler(documents, recipientId);
			handleUpdateRecipientsAndTabs(allDropItems, recipients);
			setConfigDocLoadingStatus("completed");
			// updateDocumentState()
			setActiveMenuTab('standard');
			setUploadedEnvelopeDocs({ isLoading: false, data: envelopeList });
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[assignColorFromLocalRecipient, generateRecipients, handleUpdateRecipientsAndTabs, multiDocHandler]
	);

	const fetchDocTemplate = useCallback(async () => {
		setConfigDocLoadingStatus("loading");
		// here i am fetching the templates get api
		const resp = await get(`${API_URL.TEMPLATE}/${templateId}`);
		const { apiData, response } = resp ?? {};
		if (response.status === 200) {
			setIsEditorNodesLoaded(false);
			const {
				documents,
				customTabs,
				questionaire,
				configuration,
				name,
				createdAt,
				recipients,
				type,
				whiteLabelInfo,
			} = apiData.data;
			setConfigDocTitle({ name, createdAt });
			setCustomFields(customTabs);
			setQuestionaireFields(questionaire);
			setConfiguration(configuration);
			if (!customTabs.length) {
				setActiveMenuTab('standard');
			}
			// set white label data
			if (whiteLabelInfo?.whitelabel) {
				setOrganization(whiteLabelInfo);
			}
			setTemplateType(type);
			// Adding the validation fields to the text node to rerender on edit
			const newDocuments = documents.map((doc: IDocument) =>{
				doc.tabs.map((tab: ITab) =>{
					const validations = tab.metadata.validations;
					if(tab.type ==="text" && validations){
						if(validations.type !== VALIDATION_TYPES[0])
						tab.type = validations.type as IFieldNodeType;
						tab.metadata.validation = validations;
						tab.metadata.validations = { label: validations.type, value: validations.type as string }
						tab.metadata.formate = {label: validations.value, value: validations.value}
					}
					return tab;
				})
				return doc;
			});

			setEnvelopeValues(recipients, newDocuments);
			handleUpdateDocumentsFamily()
			return;
		}
		setConfigDocLoadingStatus("completed")
		navigate(APP_ROUTES.ERROR);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateId]);

	const fetchTabMenuItems = useCallback(async () => {
		if (apiHostExists) {
			const resp = await get(`${API_URL.TABS}`);
			const { apiData, response } = resp;
			if (response.status === 200) {
				setMenuTab(apiData?.data[0] ?? {});
				return;
			}
		}

		// navigate(APP_ROUTES.ERROR);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apiHostExists, get]);

	const handleDeleteNode = useCallback(
		(nodeId: string) => {
			setDropItems((prev) => {
				const prevState = JSON.parse(JSON.stringify(prev));
				const foundIndex = prevState.findIndex(
					(item: IDropedItems) => item.id === nodeId
				);
				if (foundIndex !== -1) {
					prevState.splice(foundIndex, 1);
				}
				return prevState;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const showMessageAndRedirect = useCallback(
		(isTemplate: boolean) => {
			if (isTemplate) {
				successNotification(TEMPLATE_SAVED_MESSAGE);
				setTimeout(() => {
					window.location.href = `/config-success-screen`;
				}, 1000);
			} else {
				window.location.href = `/invitation-success-screen`;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// save template patch api will be called in here.
	const handleSaveTemplate = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (payload: any): Promise<boolean> => {
			setIsLoading(true);
			const resp = await patch(`${API_URL.TEMPLATE}/${templateId}`, payload);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const { response, errorResponse }: any = resp;
			if (errorResponse) {
				errorNotification(API_ERROR_MESSAGE);
				setIsLoading(false);
				return false;
			}
			if (response?.status === 200) {
				setIsLoading(false);
				showMessageAndRedirect(true);
				return true;
			}
			errorNotification(response?.message ?? API_ERROR_MESSAGE);
			setIsLoading(false);
			return false;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[location]
	);

	const fetchEnvelope = useCallback(
		async (id?: string) => {
			const envelopeId = id || GetQueryParams('envelopeId');
			setConfigDocLoadingStatus("loading");
			const url = readOnly
				? `${API_URL.ENVELOPE}/${envelopeId}?view=true`
				: `${API_URL.ENVELOPE}/${envelopeId}`;
			const resp = await get(url);
			setConfigDocLoadingStatus("completed");
			const { apiData, response } = resp ?? {};
			if (response.status === 200) {
				setPrepareDashboard(apiData?.data);
				if (apiData?.data?.sender) {
					setSenderDetails({
						...apiData?.data?.sender,
						envelopeId: apiData?.data?._id,
					});
				}
				// set white label data
				if (apiData?.data?.whiteLabelInfo?.whitelabel) {
					setOrganization(apiData?.data?.whiteLabelInfo);
				}
				setIsEditorNodesLoaded(false);
				const {
					recipients,
					documents,
					name = 'Untitled',
					configuration,
					createdAt,
					origin,
					questionnaire,
					purpose,
					customTabs,
					reviewSign,
					signOrder,
					sender,
					whiteLabelInfo
				} = apiData.data;
				if (whiteLabelInfo?.whitelabel) {
					setOrganization(whiteLabelInfo);
				}
				setUserAuthType(apiData.data?.authType);
				setPurpose(purpose);
				setActiveTab(purpose === ENVELOPE_PURPOSE.SELFSIGN ? purpose : ENVELOPE_PURPOSE.WORKFLOW);
				setConfigDocTitle({ name, createdAt });
				setQuestionaireFields(questionnaire);
				setConfiguration(configuration);
				setCustomFields(customTabs ?? []);
				setIsMerchantFlow(reviewSign);
				setSigningOrderEnabled(signOrder);
				if (origin) {
					setTemplateType(origin);
				}
				if (apiData?.data?.recipients) {
					const isOriginalCopyAvailable = apiData?.data?.recipients?.filter(
						(data: IRecipientUser) => {
							return data.originalCopy;
						}
					);
					if (isOriginalCopyAvailable.length === 1 && apiData?.data?.recipients?.length > 1) {
						setIsOriginalCopy(true);
					} else {
						setIsOriginalCopy(false);
					}
				}
				setEnableFacialAuth(
					apiData?.data?.authType === 'facial' ? true : false
				);
				setIsEnableENotary(!!apiData?.data?.notary);
				let newRecipients = [...recipients] as IRecipientUser[];
				if (recipients.length && recipients[0].title && !recipients[0].fullName) {
					newRecipients = recipients.map((recipient: IRecipientUser, index: number) => {
						if (index === 0) {
							return ({...recipient, fullName : sender.fullName , email: sender.email})
						}
						return recipient
					})
				}
				const newDocuments = documents.map((doc: IDocument) =>{
					const AllTabs = doc.tabs.map((tab: ITab) =>{
						const tabData = structuredClone(tab);
						const validations = tabData.metadata.validation;
						if(tabData.type ==="text" && validations){
							if(validations.type !== VALIDATION_TYPES[0])
							tabData.type = validations.type as IFieldNodeType;
							tabData.metadata =  { 
									...tabData.metadata, 
									validation: validations,
									validations: { label: validations.type, value: validations.type as string },
									formate: {label: validations.value, value: validations.value}
							}
						}
						return tabData;
					})
					return {
                        ...doc,
                        tabs: AllTabs
                    }
				});
				setEnvelopeValues(newRecipients, newDocuments);
				handleUpdateDocumentsFamily()
				return apiData?.data
			}
			navigate(APP_ROUTES.ERROR);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ handleUpdateDocumentsFamily, readOnly]
	);

	const handleSaveEnvelope = useCallback(
		/**
		 * used to patch envelope data with payload
		 * @param payload payload to be patched for the envelope
		 * @param id envelope id if available else taken from query params
		 * @param shouldRedirectAndSendInvite flag to check whether it should redirect to success page
		 * @returns Promise with boolean which denotes success or failure
		 */
		async (
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			payload: any,
			id?: string,
			shouldRedirectAndSendInvite = false
			// purpose? : IPurpose
		): Promise<boolean> => {
			const envelopeId =
				id || GetQueryParams('envelopeId') || webComponentEnvelopeId;
			setConfigDocLoadingStatus("loading")
			const envelopePatchURL = shouldRedirectAndSendInvite
				? `${API_URL.MULTI_SIGN}/${envelopeId}?sendInvite=true`
				: `${API_URL.MULTI_SIGN}/${envelopeId}`;
			const resp = await patch(envelopePatchURL, payload);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const { response, errorResponse, apiData }: any = resp;
			if (errorResponse) {
				errorNotification(API_ERROR_MESSAGE);
				setConfigDocLoadingStatus("completed")
				return false;
			}
			if (response?.status === 200) {
				const { recipients, purpose } = apiData?.data ?? {};
				if (purpose === 'selfsign') {
					if (paramId) {
						const signUrl = `/${APP_ROUTES.SELF_SIGN}/${envelopeId}?recipient=${recipients?.[0]?._id}`;
						navigate(signUrl);
					} else {
						setShowSelfSignDoc(true);
					}
					return false;
				}
				if (shouldRedirectAndSendInvite) {
					setInvitationSentStatus(true);
				} else {
					const recipients = generateRecipients(
						assignColorFromLocalRecipient(apiData?.data.recipients)
					);
					setRecipients(recipients);
					setLocalRecipients(recipients);
					/**
					 * first signer recipient
					 */
					const tempSelectedRecipient = recipients.find(
						(item) => !!item.action.match(regexNeedsToSign)
					);
					// set the first signer recipient as the selected one
					if (tempSelectedRecipient) {
						setSelectedRecipient([tempSelectedRecipient]);
					}

					/**
					 * all signer recipient ids as a map with value 1(truthy)
					 */
					const recipientIds: { [key: string]: number } = {};
					recipients
						.filter((_recipient) => !!_recipient.action.match(regexNeedsToSign))
						.forEach(({ id }) => {
							if (id) {
								recipientIds[id] = 1;
							}
						});

					/** if drop items does not have a recipient id assign the first recipient to them */
					if (
						tempSelectedRecipient?.id &&
						dropItems.find((item) => !item.recipient)
					) {
						setDropItems((prev) =>
							prev
								.filter(
									/**remove the drop items which has invalid recipient ids except empty
									 * string which will be assigned the first signer recipient */
									({ recipient }) => (!recipient || !!recipientIds[recipient])
								)
								.map((item) => {
									if (item.source === TAB_SOURCE.COMMON) {
										return item;
									}
									return item.recipient
										? item
										: {
												...item,
												recipient: tempSelectedRecipient?.id || '',
									}
								}
								)
						);
					} else {
						setDropItems((prev) =>
							prev.filter(
								/**remove the drop items which has invalid recipient ids except empty
								 * string which will be assigned the first signer recipient */
								({ recipient }) => !!recipientIds[recipient]
							)
						);
					}
				}
				setConfigDocLoadingStatus("completed");
				return true;
			}
			errorNotification(response?.message ?? apiData?.message ?? API_ERROR_MESSAGE);
			setConfigDocLoadingStatus("completed");
			return false;
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			API_ERROR_MESSAGE,
			assignColorFromLocalRecipient,
			dropItems,
			errorNotification,
			generateRecipients,
			navigate,
			patch,
			setDropItems,
			setLocalRecipients,
			setRecipients,
			setSelectedRecipient,
			setShowSelfSignDoc,
			showMessageAndRedirect,
			webComponentEnvelopeId,
		]
	);

	const statusError = useCallback(
		async (payload: string) => {
			await patch(`${API_URL.STATUS}/${paramId}`, {
				status: payload,
			});
		},
		[paramId, patch]
	);

	const handleDeletePage = useCallback(
		(docId: string, docIndex: number, pageIndex: number) => {
			remove(`${API_URL.PAGE}/${docId}?pageIndex=${pageIndex}`);
			/**
			 * here we are removing the selected document page
			 */
			setUploadedEnvelopeDocs((prev) => {
				const prevState = structuredClone(prev).data;
				const foundDocument = prevState[docIndex];
				if (foundDocument) {
					const {pages} = foundDocument;
					if (pages.length === 0) {
						prevState?.splice(pageIndex, 1);
					}else {
						prevState.splice(docIndex,1,foundDocument)
					}
				}
				return {...prev, prevState}
			})

			/**
			 * here we are removing the node items that we added on the deleted page
			 */
			setDropItems((prev) => {
				const prevState = [...prev];
				const remainingNodes: IDropedItems[] = [];
				prevState.forEach((node) => {
					/**
					 * here we are deleting nodes
					 */
					if (node.documentId === docId && node.pageNumber === pageIndex + 1) {
						return;
					}
					/**
					 * here we are updating the page numbers of the nodes
					 * that were on the below pages from the deleted page
					 */
					if (node.pageNumber > pageIndex + 1) {
						remainingNodes.push({ ...node, pageNumber: node.pageNumber - 1 });
						return;
					}
					/**
					 * here we are pushing the remaining nodes in the array
					 * that well be later on replacing the origin nodes list
					 */
					remainingNodes.push(node);
				});
				return remainingNodes;
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	return {
		handleDeleteNode,
		fetchDocTemplate,
		fetchTabMenuItems,
		handleSaveTemplate,
		statusError,
		isLoading,
		handleSaveEnvelope,
		fetchEnvelope,
		handleDeletePage,
		multiDocHandler,
		handleUpdateRecipientsAndTabs,
	};
};
