import { FC, Fragment, useEffect, useRef } from 'react';

import {
	ConfigDocument,
	ConfigHeader,
	CustomDragLayer,
	DropItemsState,
	IDropedItems,
	IFieldNodeType,
	PreviewDocument,
} from 'views/editor-dashboard';
import { GifLoader } from '@storybook';
import { CreateSignModal, CreateSignState } from 'views/create-sign';
import { IAuthUserAuthType, useSetSignDoc } from 'views/signing-dashboard';
import { GetInitials } from 'utils';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { TermsCondition } from 'views/terms-&-condition';
import { useNetwork, useQueryParams } from 'hooks';
import { FacialBiometricModal } from 'views/facial-biometric/facial-biometric';
import { IsFacialModalOpen } from 'views/facial-biometric/store';
import { UserKYCFailed, UserKyc } from 'views/user-kyc';
import { ServerError } from 'views/server-error';
import { SuccessScreen } from 'views/success-screen';
import { VALIDATION_TYPES } from 'views/config-items';
import { IsShowTandCState } from 'views/terms-&-condition/store/state';

import { FieldsSidebar, Footer } from './components';
import { SelfSignSuccessState, selfSignSelector, useGetSelfSign, useSelfSign } from './store';

interface ISelfSign {
	envelopeId?: string;
}

export const SelfSign:FC<ISelfSign> = ({envelopeId}) => {
	const changes = useRef<IDropedItems[][]>([]);
	const undoPointer = useRef<number>(0);
	const isUndoRedo = useRef<boolean>(false);
	const { fetchSelfSign, selfSignLoaded } = useSelfSign(envelopeId);
	const { getTheDefaultValue } = useGetSelfSign();
	const { fetchSignFonts } = useSetSignDoc();
	const setSignerName = useSetRecoilState(CreateSignState);
	const selfSignSuccess = useRecoilValue(SelfSignSuccessState);
	const setDropItems = useSetRecoilState(DropItemsState);
	const isToggleConcent = useRecoilValue(IsShowTandCState);
	const isModalOpen = useRecoilValue(IsFacialModalOpen);
	const userAuthType = useRecoilValue(IAuthUserAuthType);
	const userKycFailed = useRecoilValue(UserKYCFailed);
	const { apiHostExists } = useNetwork();
	const { selfSigner, envelopeId: selfSignEnvelopeId } = useRecoilValue(selfSignSelector);
	
	const {getEnvelopeIdParams} = useQueryParams({updateState: true})
	
	useEffect(() => {
		if (apiHostExists) {
			fetchSelfSign();
			getEnvelopeIdParams(envelopeId);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [envelopeId, apiHostExists]);

	useEffect(() => {
		
		const fullName = getTheDefaultValue.name as string;
		if (fullName) {
			fetchSignFonts(fullName, GetInitials(fullName));
			setSignerName((prev) => ({
				...prev,
				fullName,
				initials: GetInitials(fullName),
			}));
		}
		setDropItems((prev: IDropedItems[]) => {
			return prev.map((item) => {
				if (item.key === "signedDate" && item.source === "standard") {
					return item
				}
				const validations = item.metadata.validations;
				let fieldType = item.fieldType;
				if(item.fieldType ==="text" && validations){
					if(validations.type !== VALIDATION_TYPES[0])
						fieldType = validations.type as IFieldNodeType;
					const fieldvalidations = { label: validations.type, value: validations.type as string }
					const formate = {label: validations.value, value: validations.value}
					item = { ...item, metadata: { ...item.metadata, validations: fieldvalidations, formate: formate, validation: validations}}
				}
				return { ...item, value: getTheDefaultValue[item.fieldType] ?? '', fieldType: fieldType };
			});
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getTheDefaultValue]);

	if (!selfSignLoaded) {
		return (
			<div className="self-sign__loader-wrapper">
				<GifLoader dimension={60} />
			</div>
		);
	}

	if (selfSignSuccess) {
		return <SuccessScreen message="Document Signed Successfully" />;
	}
	if (userKycFailed) {
		return <ServerError/>
	}

	return (
		<Fragment>
			<UserKyc recipientId={selfSigner?._id ?? ""} envelopeId={selfSignEnvelopeId ?? ""} />
			{isToggleConcent && (
				<div className="esign-signing-screen__tnc-container">
					<TermsCondition />
				</div>
			)}
			<div className="self-sign__wrapper" style={{position: "relative"}}>
				<div className="self-sign__header">
					<ConfigHeader
						refs={{ changes, undoPointer, isUndoRedo, readOnly: false }}
					/>
				</div>
				<CustomDragLayer />
				<div className="self-sign__body">
					<div className="self-sign__left-sidebar">
						<FieldsSidebar />
					</div>
					<div className="self-sign__config-area">
						<ConfigDocument refs={{ changes, undoPointer, isUndoRedo }} showZindex={false} />
					</div>
					<div className="self-sign__preview-area">
						<PreviewDocument />
					</div>
				</div>
				<div className="self-sign__footer">
					<Footer />
				</div>
			</div>
			<CreateSignModal />
			{userAuthType === 'facial' && 
			isModalOpen && <FacialBiometricModal />}
		</Fragment>
	);
};
