import type { IDropedItems } from '../../store';
import type { IPosition } from 'views';

import {
	FC,
	MutableRefObject,
	useEffect,
	useRef,
	CSSProperties,
	useState,
	PropsWithChildren,
	RefObject,
	Dispatch,
	SetStateAction,
} from 'react';

import { DropTargetMonitor, useDrop } from 'react-dnd';
import { v4 } from 'uuid';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useGetRecipient, IsConfigDrawerOpen } from 'views';
import { GifLoader } from '@storybook';
import { IsViewOnlyConfigState } from 'states';
import { generateDocumentUrl } from 'utils/get-document-url';

import {
	DragTypes,
	DropItems,
	DropItemsState,
	EditorNodesLoadedState,
	SelectedConfigNode,
	useCoordinates,
} from '..';


interface IDocumentFooter {
	documentName: string;
	index: number;
	minWidth: number;
}

const DocumentFooter: FC<IDocumentFooter> = ({
	documentName,
	index,
	minWidth,
}) => {
	const footerWrapperStyle: CSSProperties = {
		minWidth: minWidth,
		width: '100%',
		margin: 'auto',
	};
	return (
		<div
			className="config-document__doc-description"
			style={footerWrapperStyle}
		>
			<span className="config-document__doc-title">{documentName ?? ''}</span>
			<span className="config-document__doc-page-number">{`Page ${
				index + 1
			}`}</span>
		</div>
	);
};

const DocumentImage = ({
	_id,
	imgLoading,
	setImgLoading,
}: {
	_id: string;
	setImgLoading: Dispatch<SetStateAction<boolean>>;
	imgLoading: boolean;
}) => {
	const path = generateDocumentUrl(_id);

	return imgLoading ? (
		<div className="config-document__loader-wrapper">
			<GifLoader dimension={60} />
			Loading document page...
		</div>
	) : (
		<img
			draggable={false}
			className="config-document__document"
			id="image-generated"
			src={path}
			alt="pdfImage"
			loading="lazy"
			style={{
				width: '100%',
				height: '100%',
			}}
			onError={() => setImgLoading(true)}
			onLoad={() => setImgLoading(false)}
			key={_id}
		/>
	);
};

interface IDropNodesWrapper {
	isEditorNodesLoaded: boolean;
	_id: string;
	width: number;
	height: number;
	idx: number;
	documentId: string;
	showZIndex: boolean;
}

const DropNodesWrapper: FC<IDropNodesWrapper> = ({
	_id,
	documentId,
	height,
	idx,
	showZIndex,
	width,
	isEditorNodesLoaded,
}) => {
	return (
		<DropItems
			pageId={_id}
			pageWidth={width}
			pageHeight={height}
			pageNumber={idx + 1}
			docId={documentId}
			showZIndex={showZIndex}
		/>
	);
	return isEditorNodesLoaded ? (
		<DropItems
			pageId={_id}
			pageWidth={width}
			pageHeight={height}
			pageNumber={idx + 1}
			docId={documentId}
			showZIndex={showZIndex}
		/>
	) : (
		<div className="config-document__nodes-loading">
			<GifLoader dimension={60} />
		</div>
	);
};

/**
 * config document drop wrapper
 * */

const ConfigDocumentDropWrapper = ({
	containerRef,
	children,
}: PropsWithChildren<{ containerRef: RefObject<HTMLDivElement> }>) => {
	return (
		<div
			style={{
				width: '100%',
				display: 'grid',
			}}
			ref={containerRef}
		>
			{children}
		</div>
	);
};

type Props = {
	_id: string;
	height: number;
	width: number;
	idx: number;
	documentName: string;
	documentId: string;
	dropContainerDimension: MutableRefObject<{ width: number; height: number }>;
	showZindex: boolean;
};

/**
 * config document drop
 * */
export const ConfigDocumentDrop: FC<Props> = ({
	_id,
	height,
	width,
	idx,
	documentId,
	documentName,
	dropContainerDimension,
	showZindex,
}) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const acceptableTypes = [DragTypes.DroppedItem, DragTypes.SideBarItem];
	const setIsDrawerOpen = useSetRecoilState(IsConfigDrawerOpen);
	const setDropItems = useSetRecoilState(DropItemsState);
	const [activeNode, setActiveNode] = useRecoilState(SelectedConfigNode);
	const { activeRecipientId } = useGetRecipient();
	const { getNewXYCoordinates } = useCoordinates();
	const isEditorNodesLoaded = useRecoilValue(EditorNodesLoadedState);
	const readOnly = useRecoilValue(IsViewOnlyConfigState);
	const [imgLoading, setImgLoading] = useState(false);
	const [, dropRef] = useDrop({
		accept: acceptableTypes,
		// !TODO need to ask the type for drop function because drop takes parameter of item as unknown
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		drop(item: IDropedItems | any, monitor: DropTargetMonitor) {
			const dropContainer = document.getElementById(_id);
			const dropPage = idx + 1;
			if (dropContainer) {
				const container = dropContainer.getBoundingClientRect();
				const itemWidth = item?.offsetWidth ?? 50;
				const itemHeight = item?.offsetHeight ?? 50;
				const { x: clientX, y: clientY } = monitor.getSourceClientOffset() || {
					x: 0,
					y: 0,
				};
				const { width: containerWidth, height: containerHeight } = container;
				dropContainerDimension.current = {
					width: containerWidth,
					height: containerHeight,
				};
				const [positionFromLeftPercent, positionFromTopPercent] =
					getNewXYCoordinates(
						container,
						itemWidth,
						itemHeight,
						clientX,
						clientY,
						{ offsetX: 0, offsetY: 0 }
					);
				if (positionFromLeftPercent && positionFromTopPercent) {
					if (monitor.getItemType() === DragTypes.DroppedItem) {
						const { metadata } = item;
						const { height: elementHeight, width: elementWidth } = metadata;
						const nodeWidth = parseInt(`${elementWidth}`.replace('px', ''));
						const nodeHeight = parseInt(`${elementHeight}`.replace('px', ''));

						// Calculate new positions based on container size
						const xLeft = positionFromLeftPercent;
						const yTop = positionFromTopPercent;
						const xRight =
							(xLeft * containerWidth + nodeWidth) / containerWidth;
						const yBottom =
							(yTop * containerHeight + nodeHeight) / containerHeight;
						const oldItem = {
							...item,
							pageNumber: dropPage,
							position: {
								xLeft,
								yTop,
								xRight,
								yBottom,
							} as IPosition,
							documentId,
							isNewField: true,
						} as IDropedItems;
						if (item.fieldType === 'text') {
							oldItem.pageWidth = width;
							oldItem.containerWidth = container.width;
						}
						setDropItems((items: IDropedItems[]) => {
							const filteredItems = items.filter(({ id }) => id !== item.id);
							filteredItems.push(oldItem);
							return filteredItems;
						});

						setActiveNode({
							fieldType: oldItem.fieldType,
							index: idx,
							id: oldItem.id,
							isEditable: oldItem.isEditable,
							recipient: oldItem.recipient,
							source: oldItem.source,
						});
					} else if (monitor.getItemType() === DragTypes.SideBarItem) {
						const newItem = {
							...item,
							pageNumber: dropPage,
							recipient: activeRecipientId,
							id: v4(),
							position: {
								xLeft: positionFromLeftPercent,
								yTop: positionFromTopPercent,
							},
							metadata: {},
							documentId,
							isNewField: true,
						} as IDropedItems;
						if (item.fieldType === 'text') {
							newItem.pageWidth = width;
							newItem.containerWidth = container.width;
						}
						setDropItems((items: IDropedItems[]) => [...items, newItem]);
						setActiveNode({
							fieldType: newItem.fieldType,
							index: idx,
							id: newItem.id,
							isEditable: newItem.isEditable,
							recipient: activeRecipientId,
							source: newItem?.source ?? '',
						});
					}
				}
				setIsDrawerOpen(true);
			}
		},
	});

	useEffect(() => {
		const element = containerRef.current?.querySelector(`.drop-item--active`);
		if (element) {
			(element as HTMLElement).focus({ preventScroll: true });
		}
	}, [activeNode]);

	const documentWrapperStyle: CSSProperties = {
		minHeight: height,
		minWidth: width,
		width: '100%',
		height: '100%',
		margin: 'auto',
		pointerEvents: readOnly ? 'none' : 'auto',
	};

	return (
		<ConfigDocumentDropWrapper containerRef={containerRef}>
			<div
				className="config-document__document-wrapper"
				id={_id}
				style={{ ...documentWrapperStyle }}
				ref={dropRef}
			>
				<DocumentImage
					_id={_id}
					setImgLoading={setImgLoading}
					imgLoading={imgLoading}
				/>
				{!imgLoading && (
					<DropNodesWrapper
						_id={_id}
						documentId={documentId}
						height={height}
						idx={idx}
						isEditorNodesLoaded={isEditorNodesLoaded}
						showZIndex={showZindex}
						width={width}
					/>
				)}
			</div>
			<DocumentFooter
				documentName={documentName}
				index={idx}
				minWidth={width}
			/>
		</ConfigDocumentDropWrapper>
	);
};
