export const APP_ROUTES = {
	CONFIG_DOC: 'config-doc',
	SIGN_DOC: 'sign-doc',
	ERROR: 'server-error',
	SIGNINSUCCESS: 'signin-success-screen',
	VIEW_SUCCESS: 'view-success-screen',
	CONFIGSUCCESS: 'config-success-screen',
	PREPARE: 'prepare',
	ENVELOPE: 'envelope',
	ENVELOPE_CONFIG_SUCCESS: 'invitation-success-screen',
	REDIRECT_TO_SIMPLICI: 'back-to-simplici',
	VIEW_DOC: 'view-doc',
	OVERLAY: "overlay",
	LINK_EXPIRED: "/link-expired",
	SELF_SIGN: "self-sign",
};

export type IEnv = "stage" | "preprod" | "prod" | "beta"

export const ENV_HOST: { [key in IEnv]: { host: string} } =
	{
		stage: {
			host: 'https://api.stage.satschel.com/v2/esign',
		},
		preprod: {
			host: 'https://api.pp.satschel.com/v2/esign',
		},
		prod: {
			host: 'https://api.satschel.com/v2/esign',
		},
		beta: {
			host: 'https://api.beta.satschel.com/v2/esign',
		},
	};
