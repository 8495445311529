import { PropsWithChildren, useEffect, useState } from 'react';

import { Loader, Modal } from '@storybook';

type IStep = 'step1' | 'step2' | 'step3';

const stepDescription: { [key in IStep]: string } = {
	step1: 'Processing document...',
	step2:
		'Once the first few pages are ready, you can start using them while we process the rest...',
	step3: "Taking longer than usual, please be patient."
};

const LoaderWrapper = ({
	children,
	isOpen,
}: PropsWithChildren<{ isOpen: boolean }>) => {
	return (
		<Modal
			modalName=""
			closeOnEscBtn={false}
			showCloseBtn={false}
			isStopOutsideClick={true}
			className="processing-document"
			isOpen={isOpen}
		>
			{children}
		</Modal>
	);
	// return <div className="processing-document">{children}</div>;
};

const LoaderDescription = ({ step = 'step1' }: { step: IStep }) => {
	return (
		<div className="processing-document__step-description">
			{stepDescription[step]}
		</div>
	);
};

export const ProcessingDocument = ({isOpen}: {isOpen: boolean}) => {
	const [step, setStep] = useState<IStep>('step1');

	useEffect(() => {
		setInterval(() => {
			setStep((prevStep) => {
				return prevStep === "step1" ? "step2" : "step1"
            });
		}, 4000);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<LoaderWrapper isOpen={isOpen}>
			<Loader className="loader-blue" dimension={50} />
			<LoaderDescription step={step} />
		</LoaderWrapper>
	);
};
