/**
 * @Manish Import necessary dependencies from external libraries and modules
 * */
import type { IRecipientUser } from '../store';

import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo } from 'react'; // Import React's FC and useCallback hooks

import { IInputChange } from 'types';
import { IOption, IconBtn } from '@storybook'; // Import components from Storybook
import DropDownWithLabel from '@storybook/drop-down-with-label/drop-down-with-label'; // Import a custom DropDownWithLabel component
import { useRecoilValue } from 'recoil'; // Import a hook from Recoil state management
import { prepareTypeState, templateTypeState } from 'views/prepare-dashboard';
import { GetQueryParams } from 'utils';
import { EnvelopePurposeState } from 'states';
import { ENVELOPE_PURPOSE, RECIPIENT_TYPE } from 'constant';

import {
	ACTION_DROP_DOWN_OPTIONS, IsEnableMerchantFlow, IsOriginalCopy, RecipientLocalState, // Import a constant for dropdown options
	useFocusElement, // Import a custom hook for focusing elements
	useRecipient, // Import custom hooks related to recipient data
} from '../store'; // Import custom store-related functionalities

/**
 * @Manish Define the properties that the RecipientFormAction component receives
 * */
interface IRecipientFormAction {
	index: number; // The index of the recipient in the list
	recipient: IRecipientUser; // The recipient data
	setPrivateMessageOpen: Dispatch<SetStateAction<boolean>>
}
/**
 * @Manish Define the RecipientFormAction functional component
 * */
export const RecipientFormAction: FC<IRecipientFormAction> = ({
	index,
	recipient,
	setPrivateMessageOpen
}) => {
	const templateType = useRecoilValue(templateTypeState);
	const prepareType = useRecoilValue(prepareTypeState);
	const localRecipients = useRecoilValue(RecipientLocalState);
	const isOriginalCopyToggle = useRecoilValue(IsOriginalCopy);
	const purpose = useRecoilValue(EnvelopePurposeState);
	const isMerchantFlow = useRecoilValue(IsEnableMerchantFlow);
	/**
	 * @Manish Get the focusElement function from a custom hook
	 * */
	const { focusElement } = useFocusElement();
	/**
	 * @Manish Get functions related to recipient management from a custom hook
	 * */
	const { DeleteRecipient, setRecipientsWithValue, setOriginalCopyData } = useRecipient();
	const templateId = GetQueryParams('templateId');
	const { isPrivateMessageEnabled, type } = recipient;

	/**
	 * @Manish Handle changes in the dropdown selection
	 * */
	const handleSelectChange = useCallback(
		(event: IOption, name: keyof IRecipientUser) => {
			setRecipientsWithValue(name, event.value, index);
		},
		[index, setRecipientsWithValue]
	);

	/**
	 * @Manish Generate the label for each option in the dropdown
	 * */
	const getOptionLabelForAction = useCallback((option: IOption) => {
		return (
			<div>
				<div className="options-container">
					{option.icon}
					<span className="options-label">{option.label}</span>
				</div>
			</div>
		);
	}, []);

	/**
	 * @Manish Handle clicks on the private message button
	 * */
	const handlePrivateMessageClick = useCallback((): void => {
		const enablePrivateMessage = !isPrivateMessageEnabled;
		setPrivateMessageOpen(true)
		setRecipientsWithValue(
			'isPrivateMessageEnabled',
			enablePrivateMessage,
			index
		);
		if (enablePrivateMessage) {
			focusElement(`#private-message-input-${recipient.id}`);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [focusElement, index, isPrivateMessageEnabled, recipient.id]);

	/**
	 * @Manish Handle clicks on the delete button
	 * */
	const handleDelete = useCallback(() => {
		DeleteRecipient(index);
	}, [DeleteRecipient, index]);

	const renderDeleteButton = useMemo((): JSX.Element => {
		/**
		 * Condition 1: Check if the template type is 'basic'.
		 * 
		 * Condition 2: Check if the template type is 'overlay' and prepareType is 'overlay'.
		 * 
		 * Condition 3: Check if templateId exists.
		 *
		 * Condition 4: Check if the purpose is sign agreement and the recipient type is onboarding the we hide the delete button
		 * */
		if (
			localRecipients.length > 1 &&
			(templateType === 'basic' ||
				(templateType === 'overlay' && prepareType === 'overlay') ||
				templateId) &&
			!(purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT && type === RECIPIENT_TYPE.ONBOARDING)
		) {
			return (
				<IconBtn
					handleClickIcon={handleDelete}
					btnClassName="ri-delete-bin-5-fill add-recipient__remove-recipient"
				/>
			);
		}
		return <></>;
	}, [
		handleDelete,
		localRecipients.length,
		prepareType,
		purpose,
		templateId,
		templateType,
		type,
	]);

/**
 * Determines whether the sign action dropdown should be visible based on certain conditions.
 * @returns {boolean} - True if the sign action dropdown should be visible, otherwise false.
 */
const isSignActionDropdownVisible = useMemo(() => {
	// Check if the template type is 'basic' or ('overlay' and prepare type is 'overlay') or there's a template ID
	const isDropDownVisible =
		(templateType === 'basic' ||
			(templateType === 'overlay' && prepareType === 'overlay') ||
			templateId) &&
		// Ensure it's not a multisign agreement with recipient type onboarding
		!(
			purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT && 
			(type === RECIPIENT_TYPE.ONBOARDING || (type === RECIPIENT_TYPE.DEFAULT && isMerchantFlow) )
		);

	return isDropDownVisible;
}, [templateType, prepareType, templateId, purpose, type, isMerchantFlow]);

useEffect(() => {
    // Additional effect for handling recipients with 'onboarding' local type
    if (recipient?.recipientLocalType === 'onboarding') {
        setRecipientsWithValue(
            'action',
            ACTION_DROP_DOWN_OPTIONS[0]?.value,
            index
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [index, recipient?.recipientLocalType]);


	const renderSignAction = useMemo(() => {
		// Determine whether the dropdown should be visible based on template type and conditions

		// Render either a dropdown or a div based on the visibility condition
		return isSignActionDropdownVisible ? (
			// Dropdown component for sign action
			<DropDownWithLabel
				defaultValue={ACTION_DROP_DOWN_OPTIONS[0]}
				id="action"
				handleDropdownChange={(e: IOption) => handleSelectChange(e, 'action')}
				value={ACTION_DROP_DOWN_OPTIONS.find(
					(item) => item.value === recipient.action
				)}
				label=""
				options={ACTION_DROP_DOWN_OPTIONS}
				getOptionLabel={getOptionLabelForAction}
			/>
		) : (
			// Display the recipient action in a div when the dropdown is not visible
			<div className="add-recipient__role">{recipient.action}</div>
		);
	}, [getOptionLabelForAction, handleSelectChange, isSignActionDropdownVisible, recipient.action]);

	const handleSendOriginalCopy = useCallback((e: IInputChange) => {
		const recipient_id = e.target.value;
		setOriginalCopyData(recipient_id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[]);

	/**
	 * @Manish Render the component's UI
	 * */
	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
			<div
				className="add-recipient__original-copy__container"
				style={{
					display: isOriginalCopyToggle ? 'flex' : 'none',
				}}
			>
				<input
					className="add-recipient__original-copy__radio-btn"
					type="radio"
					value={recipient.id ?? recipient._id}
					checked={recipient?.originalCopy}
					onChange={handleSendOriginalCopy}
				/>
				<span className="add-recipient__original-copy__title">
					Original copy
				</span>
			</div>
			<div className="add-recipient__assign">{renderSignAction}</div>
			<IconBtn
				btnClassName={'ri-message-2-line'}
				className={
					recipient?.isPrivateMessageEnabled
						? 'private-message-button-active'
						: 'private-message-button-inactive'
				}
				handleClickIcon={handlePrivateMessageClick}
				id={recipient.id}
				toolTip='Private message'
			/>
			{renderDeleteButton}
		</div>
	);
};
