import React from 'react';

const CanvasWrapperComponent = () => {
    return <canvas className="Esign-output_canvas" id="Esign-output_canvas"></canvas>;
};

const CanvasWrapper = React.memo(CanvasWrapperComponent);

CanvasWrapper.displayName = 'CanvasWrapper';

export { CanvasWrapper };
